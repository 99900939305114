import React, { useState, useEffect, useRef } from "react";
import { Line } from "react-chartjs-2";
import "chart.js/auto";
import SaveTraining from "./SaveTraining";
import { Howl } from "howler";
import startSound from "./start.wav"

import {
  Button,
  Typography,
  Divider,
  Card,
  Row,
  Col,
  Progress,
  Tag,
  Input,
  Popover,
  Modal
} from "antd";
import "../App.css"; // Make sure to import your CSS file
import {
  UpSquareFilled,
  DownSquareFilled,
  ApiOutlined,
  DisconnectOutlined,
  ForwardOutlined,
  LinkOutlined,
  RollbackOutlined,
  PlaySquareOutlined,
  FastForwardOutlined,
  PauseOutlined,
  RobotOutlined,
  TrophyOutlined,
  NotificationOutlined,
  CheckOutlined,
  RiseOutlined,
  FallOutlined,
  HourglassOutlined,
  AlertOutlined,
  FieldTimeOutlined,
  HeartOutlined,
  Loading3QuartersOutlined,
  ThunderboltOutlined,
  RedoOutlined,

} from "@ant-design/icons";
import PowerGauge from "./PowerGauge";
import { max } from "lodash";
import PlaneGame from "./PlaneGame";
import logo_white from "../logo_no_bg_white.png";
import TrainingChart from "./TrainingChart";
import HomeTrainerProgress from "./HomeTrainerProgress";

const { Title, Text, Paragraph } = Typography;

const tcxHelpers = (
  <div>
    You can download your activity TCX file and manually upload it to most
    platforms, such as{" "}
    <a target="_blank" href="https://connect.garmin.com/modern/import-data">
      Garmin
    </a>{" "}
    or{" "}
    <a target="_blank" href="https://www.strava.com/upload/select">
      Strava
    </a>
    .
    <p />
    Due to access restrictions, automatic syncing of your activity is not
    currently available.
    <p />
    Session will be saved as "biking", feel free to change it as "virtual
    biking".
  </div>
);
const initialStates = {
  lastUpdate: Date.now(),
  lastInsert: Date.now(),
  serviceFTMS: null,
  currentHTValue: null,
  currentCadence: null,
  currentBPM: null,
  currentPower: null,
  serviceCP: null,
  serviceBPM: null,
  isConnected: false,
  isBPMConnected: false,
  totTime: 0,
  totTimeStep: 0,
  totTimePause: 0,
  totTimePauseStep: 0,
  startTimeSincePause: Date.now(),
  startTimeSinceStep: Date.now(),
  startTimeSinceStepPause: Date.now(),
  startTime: Date.now(),
  indexStep: 0,
  currentTargetPower: 50,
  pause: true,
  simulate: false,
  count: 0,
  currentERG: 50,
  additionalPower: 0,
  powerInfo: {
    data: [],
    avgTot: 0,
    avgSteps: [0],
    durationSteps: [0],
    slidding: 0,
    sliddingCad: 0,
    bestMinuteW: 0,
  },
  tcxLink: "",
  timerSec: -1,
  intervalId: null,
  intervalSimulationId: null,
  targetSecWatt: [],
  deviceHT: null,
  deviceBPM: null,
  modalPlanInfoIsOpen: false,
};

function HomeTrainer({
  onConnect,
  onDisconnect,
  initSteps,
  FTP,
  setStepIndex,
  isAdmin,
  setFTP,
  weight,
  setWeight,
  infoToSave,
  otherUserInfo,
  setOtherUserInfo,
  isMobile,
  setDisabledPhoneButton,
  isMute,
}) {
  const [lastUpdate, setLastUpdate] = useState(initialStates.lastUpdate);
  const [lastInsert, setLastInsert] = useState(initialStates.lastInsert);
  const [serviceFTMS, setServiceFTMS] = useState(initialStates.serviceFTMS);
  const [currentCadence, setCurrentCadence] = useState(
    initialStates.currentCadence
  );
  const [currentBPM, setCurrentBPM] = useState(initialStates.currentBPM);
  const [currentPower, setCurrentPower] = useState(initialStates.currentPower);
  const [serviceCP, setServiceCP] = useState(initialStates.serviceCP);
  const [serviceBPM, setServiceBPM] = useState(initialStates.serviceBPM);
  const [isConnected, setIsConnected] = useState(initialStates.isConnected);
  const [soundHasPlayDuringStep, setSoundHasPlayDuringStep] = useState(false);

  const [isBPMConnected, setIsBPMConnected] = useState(
    initialStates.isBPMConnected
  );
  const [modalPlanInfoIsOpen, setModalPlanInfoIsOpen] = useState(initialStates.modalPlanInfoIsOpen);
  const [totTime, setTotTime] = useState(initialStates.totTime);
  const [totTimeStep, setTotTimeStep] = useState(initialStates.totTimeStep);
  const [totTimePause, setTotTimePause] = useState(initialStates.totTimePause);
  const [totTimePauseStep, setTotTimePauseStep] = useState(
    initialStates.totTimePauseStep
  );
  const [startTimeSincePause, setStartTimeSincePause] = useState(
    initialStates.startTimeSincePause
  );
  const [startTimeSinceStep, setStartTimeSinceStep] = useState(
    initialStates.startTimeSinceStep
  );
  const [startTimeSinceStepPause, setStartTimeSinceStepPause] = useState(
    initialStates.startTimeSinceStepPause
  );
  const [startTime, setStartTime] = useState(initialStates.startTime); // Constant, no setter needed
  const [indexStep, setIndexStep] = useState(initialStates.indexStep);
  const [currentTargetPower, setCurrentTargetPower] = useState(
    initialStates.currentTargetPower
  );
  const [goNextWarning, setGoNextWarning] = useState(false);
  const [pause, setPause] = useState(initialStates.pause);
  const [simulate, setSimulate] = useState(initialStates.simulate);
  const [count, setCount] = useState(initialStates.count);
  const [currentERG, setCurrentERG] = useState(initialStates.currentERG);
  const [additionalPower, setAdditionalPower] = useState(
    initialStates.additionalPower
  );
  const [powerInfo, setPowerInfo] = useState(initialStates.powerInfo);
  const [tcxLink, setTcxLink] = useState(initialStates.tcxLink);
  const [timerSec, setTimerSec] = useState(initialStates.timerSec);
  const [intervalId, setIntervalId] = useState(initialStates.intervalId);
  const [intervalSimulationId, setIntervalSimulationId] = useState(
    initialStates.intervalSimulationId
  );
  const [targetSecWatt, setTargetSecWatt] = useState(
    initialStates.targetSecWatt
  );
  const [reset, setReset] = useState(0);
  const [steps, setSteps] = useState([...initSteps]);
  const [deviceHT, setDeviceHT] = useState(initialStates.deviceHT);
  const [deviceBPM, setDeviceBPM] = useState(initialStates.deviceBPM);
  const [currentHTValue, setCurrentHTValue] = useState(
    initialStates.currentHTValue
  );
  const [totTimeFinal, setTotTimeFinal] = useState(initSteps.map((s) => s.duration).reduce((accumulator, currentValue) => accumulator + currentValue, 0))
  const [remainingTimeStep, setRemainingTimeStep] = useState(indexStep < steps.length && indexStep >= 0
    ? steps[indexStep].duration - totTimeStep
    : 0);
  const [nextTargetPower, setNextTargetPower] = useState(initSteps.length >= 1 ? initSteps[1].watt : null)
  const [chartData, setChartData] = useState({ labels: [], datasets: [] });

  const prevSteps = useRef()

  const f1StartSound = new Howl({
    src: [startSound],
    volume: 1.0, // Adjust volume (0.0 to 1.0)
    rate: 0.8,
    preload: true
  });
  const F1StartSound = () => {
    f1StartSound.play();
    console.log("play sound")
  }

  useEffect(() => {
    let wakeLock = null;

    const requestWakeLock = async () => {
      if ("wakeLock" in navigator) {
        try {
          wakeLock = await navigator.wakeLock.request("screen");
          wakeLock.addEventListener("release", () => {
            console.log("Wake Lock was released");
          });
          console.log("Wake Lock is active");
        } catch (err) {
          console.error("Failed to acquire wake lock:", err);
        }
      } else {
        console.error("Wake Lock API not supported");
      }
    };

    if (isConnected || simulate) {
      requestWakeLock();
    }

    // Clean up the wake lock on component unmount or if isConnected changes
    return () => {
      if (wakeLock !== null) {
        wakeLock
          .release()
          .then(() => {
            console.log("Wake Lock was released by cleanup");
          })
          .catch((err) => {
            console.error("Failed to release wake lock:", err);
          });
        wakeLock = null;
      }
    };
  }, [isConnected, simulate]);

  useEffect(() => {

    prevSteps.current = [...initSteps];
    console.log("Reset state");
    // keeping connection with bt alive
    setSteps(initSteps.map(x => x))
    setLastUpdate(Date.now());
    setLastInsert(Date.now());
    setCurrentCadence(initialStates.currentCadence);
    setCurrentPower(initialStates.currentPower);
    setCurrentBPM(initialStates.currentBPM);
    setTotTime(initialStates.totTime);
    setTotTimeStep(initialStates.totTimeStep);
    setTotTimePause(initialStates.totTimePause);
    setTotTimePauseStep(initialStates.totTimePauseStep);
    setStartTimeSincePause(Date.now());
    setStartTimeSinceStep(Date.now());
    setStartTimeSinceStepPause(Date.now());
    setStartTime(Date.now());
    setIndexStep(initialStates.indexStep);
    setPause(initialStates.pause);
    setSimulate(initialStates.simulate);
    setCount(initialStates.count);
    setCurrentERG(initialStates.currentERG);
    setAdditionalPower(initialStates.additionalPower);
    setPowerInfo(initialStates.powerInfo);
    setTcxLink(initialStates.tcxLink);
    setTimerSec(initialStates.timerSec);
    setIntervalId(initialStates.intervalId);
    setIntervalSimulationId(initialStates.intervalSimulationId);
    setStepIndex(initialStates.indexStep);
    setCurrentHTValue(initialStates.indexStep);
    setSoundHasPlayDuringStep(false)
    setTotTimeFinal(initSteps.map((s) => s.duration).reduce((accumulator, currentValue) => accumulator + currentValue, 0));
    setNextTargetPower(initSteps.length >= 1 ? initSteps[1].watt : null);
    setRemainingTimeStep(indexStep < initSteps.length && indexStep >= 0
      ? initSteps[indexStep].duration - totTimeStep
      : 0);

    const watt = [];
    initSteps.forEach((s) => {
      for (let i = 0; i < s.duration; i++) {
        for (let j = 0; j < 1; j++) {
          watt.push(s.watt);
        }
      }
    });
    //setCurrentTargetPower(steps.length > 0 ? steps[0].watt : null)
    setTargetSecWatt(watt);
    setCurrentTargetPower(initSteps.length > 0 ? initSteps[0].watt : 50);
    if (intervalId !== null) {
      clearInterval(intervalId);
      console.log("Clearing interval");
    }
    intervalSimulationId && clearInterval(intervalSimulationId);
    // This will log the updated value

    // This code runs after the `simulate` state updates

    setDisabledPhoneButton(false)

    setChartData({
      labels: [
        0,
        ...Array.from({ length: watt.length }, (x, i) => i + 1),
      ],
      datasets: [
        {
          label: "Puissance (Watts)",
          //borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
          fill: true,
          showLine: false,
          lineTension: 0.1,
          data: [
            null,
            ...Array.from({ length: watt.length }, () => null),
          ],
          borderColor: [
            "rgba(75, 192, 192, 0.2)",
            ...Array.from(
              { length: watt.length },
              () => "rgba(75, 192, 192, 0.2)"
            ),
          ],
          backgroundColor: [
            "rgba(75, 192, 192, 0.2)",
            ...Array.from(
              { length: watt.length },
              () => "rgba(75, 192, 192, 0.2)"
            ),
          ],
        },
        {
          label: "Cadence (RPM)",
          //borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth: 2,
          fill: false,
          showLine: false,
          lineTension: 0.1,
          data: [
            null,
            ...Array.from({ length: watt.length }, () => null),
          ],
          borderColor: [
            null,
            ...Array.from(
              { length: watt.length },
              () => "rgba(150, 150, 150, 1)"
            ),
          ],
          backgroundColor: [
            null,
            ...Array.from(
              { length: watt.length },
              () => "rgba(150, 150, 150, 0.5)"
            ),
          ],
        },
        {
          label: "Target Power (ERG) (Watts)",
          //backgroundColor: 'rgba(75, 192, 192, 0.5)', // Ajoute de la transparence
          borderWidth: 2,
          fill: false,
          pointRadius: 5, // Taille des points augmentée pour un effet de barre
          pointHoverRadius: 15,
          lineTension: 0.1,
          showLine: false,
          data: [null, ...watt], //[...Array.from({ length: watt.length - count -1 }, () => 0)],
          borderColor: [null, ...watt.map((x) => getColor(x))],
          backgroundColor: [null, ...watt.map((x) => getColor(x))],
        },
        {
          label: "Heart Rate (Hz)",
          //borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth: 2,
          fill: false,
          showLine: false,
          lineTension: 0.1,
          data: [
            null,
            ...Array.from({ length: watt.length }, () => null),
          ],
          borderColor: [
            null,
            ...Array.from(
              { length: watt.length },
              () => "rgba(200, 100, 100, 1)"
            ),
          ],
          backgroundColor: [
            null,
            ...Array.from(
              { length: watt.length },
              () => "rgba(200, 100, 100, 0.5)"
            ),
          ],
        },
      ],
    });

  }, [reset, initSteps]); //steps

  useEffect(() => {
    if (totTime == 0 && isMobile && steps.length > 0) {
      setModalPlanInfoIsOpen(true)
    }

    const watt = [];
    steps.forEach((s) => {
      for (let i = 0; i < s.duration; i++) {
        for (let j = 0; j < 1; j++) {
          watt.push(s.watt);
        }
      }
    });
    //setCurrentTargetPower(steps.length > 0 ? steps[0].watt : null)
    setTargetSecWatt(watt);
    setCurrentTargetPower(steps.length > 0 ? steps[0].watt : 50);
    setTotTimeFinal(steps.map((s) => s.duration).reduce((accumulator, currentValue) => accumulator + currentValue, 0));

    setRemainingTimeStep(indexStep < steps.length && indexStep >= 0
      ? steps[indexStep].duration - totTimeStep
      : 0);
    setNextTargetPower(steps.length > indexStep + 1 ? steps[indexStep + 1].watt : null);

  }, [steps]);

  useEffect(() => {
    const timer = () => {
      if (timerSec === -1) {
        console.log("Init timer");
        const id = setInterval(() => {
          setTimerSec((prevData) => {
            return prevData + 1;
          });
        }, 100);
        setIntervalId(id); // Store interval ID
      }
    };

    timer();
    return () => {
      if (intervalId !== null) {
        clearInterval(intervalId);
        console.log("Clearing interval");
      }
    };
  }, [initSteps]);

  useEffect(() => {
    if (!pause) {
      const newData = [
        ...powerInfo.data,
        {
          step: indexStep,
          watt: currentPower,
          cad: currentCadence,
          bpm: currentBPM,
          ts: new Date().toISOString(),
          htValue: totTime <= 180 ? currentHTValue : null,
        },
      ]; // Append currentPower to the existing data array // tracking rw HT velu for debug but as heavy, only take first 3 min
      // Calculate total average
      const newAvgTot =
        newData.map((x) => x.watt).reduce((acc, curr) => acc + curr, 0) /
        newData.length;

      const dataStep = newData
        .filter((x) => x.step == indexStep)
        .map((x) => x.watt);

      const avgLastStep =
        dataStep.reduce((acc, curr) => acc + curr, 0) / dataStep.length;

      const newAvgStep = [
        ...powerInfo.avgSteps.slice(0, indexStep),
        avgLastStep,
      ];

      const newDurationSteps = [
        ...powerInfo.durationSteps.slice(0, indexStep),
        totTimeStep,
      ];

      // Prepare for sliding window average - ensure only the last 5 elements are considered
      const slidingData = newData.slice(-4); // Get the last 4 elements
      const newSliding =
        slidingData.map((x) => x.watt).reduce((acc, curr) => acc + curr, 0) /
        slidingData.length;
      const newSlidingCadence =
        slidingData.map((x) => x.cad).reduce((acc, curr) => acc + curr, 0) /
        slidingData.length;
      const lastMin = newData
        .filter(
          (x) => x.ts >= new Date(new Date().getTime() - 60000).toISOString()
        )
        .map((x) => x.watt);
      const avgLastMin =
        lastMin.reduce((acc, curr) => acc + curr, 0) / lastMin.length;
      const newBestMinuteW =
        powerInfo.bestMinuteW && powerInfo.bestMinuteW >= avgLastMin
          ? powerInfo.bestMinuteW
          : avgLastMin;

      // Update state with new values
      setPowerInfo({
        data: newData,
        avgTot: newAvgTot,
        avgSteps: newAvgStep,
        durationSteps: newDurationSteps,
        slidding: newSliding,
        sliddingCad: newSlidingCadence,
        bestMinuteW: newBestMinuteW,
      });
    } else {
      setPowerInfo({
        ...powerInfo,
        slidding: currentPower, //when pause still show some power
        sliddingCad: currentCadence,
      });
    }
    setRemainingTimeStep(indexStep < steps.length && indexStep >= 0
      ? steps[indexStep].duration - totTimeStep
      : 0);

    if (!soundHasPlayDuringStep && steps.length > 0 && indexStep < steps.length && steps[indexStep].duration - totTimeStep < 4 && steps[indexStep].duration - totTimeStep > 2) {
      setSoundHasPlayDuringStep(true);
      !isMute && F1StartSound();
    }

  }, [currentPower, currentCadence, currentBPM, lastUpdate, currentHTValue]);

  //const intervalRef = useRef(); // Use useRef to hold the interval ID
  //const intervalRefNext = useRef(); // Use useRef to hold the interval ID

  // useEffect(() => {
  //   const initPower =
  //     pause || indexStep == -1 || indexStep >= steps.length
  //       ? 50
  //       : currentTargetPower;
  //   setTargetPowerViaBt(serviceFTMS, initPower + additionalPower);
  // }, [additionalPower]); Now we directly change target power

  const extractRPM = (text) => {
    if (!text) {
      return null;
    }
    // Define a regular expression to match a number followed by "RPM", ignoring case and spaces
    const rpmRegex = /(\d+)\s*RPM/i;

    // Execute the regular expression on the input text
    const match = text.match(rpmRegex);

    // If a match is found, parse the number and return it
    if (match) {
      return parseInt(match[1], 10);
    }

    // If no match is found, return null
    return null;
  };

  const getColor = (intensity) => {
    if (intensity >= FTP * 1.5) {
      return "rgba(128, 0, 0, 1)";
    } else if (intensity >= FTP * 1.2) {
      return "rgba(128, 0, 128, 1)";
    } else if (intensity >= FTP * 1.05) {
      return "rgba(255, 0, 0, 1)";
    } else if (intensity >= FTP * 0.9) {
      return "rgba(255, 165, 0, 1)";
    } else if (intensity >= FTP * 0.75) {
      return "rgba(255, 215, 0, 1)";
    } else if (intensity > FTP * 0.55) {
      return "rgba(0, 128, 0, 1)";
    } else {
      return "rgba(153, 204, 255, 1)";
    }
  };

  const getWarningColor = () => {
    if (
      indexStep < steps.length && !pause &&
      (Math.round(powerInfo.slidding) > currentTargetPower * 1.1 ||
        Math.round(powerInfo.slidding) < currentTargetPower * 0.9)
    ) {
      return "red";
    } else {
      return "rgba(0,0,65,1)";
    }
  };

  const simulateTrainer = () => {
    // Check to ensure we don't start multiple intervals
    const withBpm = !isBPMConnected;

    intervalSimulationId && clearInterval(intervalSimulationId);
    console.log("Simulation started");
    const id = setInterval(() => {
      const randomPower = Math.max(
        Math.floor(Math.random() * 40) + currentERG - 20,
        0
      );
      const randomBPM =
        Math.floor(Math.random() * 20) +
        Math.min(Math.max(randomPower / FTP + 30, 80), 160) -
        10; // Random power between 100 and 200
      const randomCadence = Math.floor(Math.random() * 3) + 90; // Random cadence between 90 and 100
      setCurrentCadence(randomCadence);
      setCurrentPower(randomPower);
      withBpm && setCurrentBPM(randomBPM);
    }, 250);
    setIntervalSimulationId(id);
  };

  useEffect(() => {
    simulate && simulateTrainer();
  }, [currentERG]);

  // Clean up interval when component unmounts to prevent memory leaks
  useEffect(() => {
    return () => {
      intervalId && clearInterval(intervalId);
      intervalSimulationId && clearInterval(intervalSimulationId);
      isConnected && disconnectFromDevice();
      isBPMConnected && disconnectFromBPM();
    };
  }, []);


  // Calculer les watts ciblés en fonction du temps écoulé
  const calculateTargetWatt = (elapsedTime) => {
    let accumulatedDuration = 0;
    let i = 0;
    for (const step of steps) {
      accumulatedDuration += step.duration;
      if (elapsedTime < accumulatedDuration) {
        return { targetWatt: step.watt, index: i };
      }
      i += 1;
    }
    return { targetWatt: 50, index: i }; // Retourner 0 si aucune étape correspondante n'est trouvée
  };

  useEffect(() => {
    if (!pause) {
      const currentTime = Date.now();
      const elapsedTime = Math.floor(
        (currentTime - startTime - totTimePause) / 1000
      ); // Temps en secondes
      const elapsedTimeStep = Math.floor(
        (currentTime - startTimeSinceStep - totTimePauseStep) / 1000
      ); // Temps en secondes
      setTotTime(elapsedTime);
      setTotTimeStep(elapsedTimeStep);
      const stepInfo = calculateTargetWatt(elapsedTime);
      if (stepInfo.targetWatt && (indexStep != stepInfo.index)) {
        setNextTargetPower(indexStep < steps.length - 2 ? steps[indexStep + 2].watt : null);
        setIndexStep(indexStep + 1);
        setStepIndex(indexStep + 1); //use in other state
        setSoundHasPlayDuringStep(false)
        setTotTimePauseStep(0);
        setStartTimeSinceStep(Date.now());
        setTargetPowerViaBt(serviceFTMS, stepInfo.targetWatt);
        setCurrentTargetPower(stepInfo.targetWatt);
      }
      else if (stepInfo.targetWatt !== currentTargetPower) {
        setTargetPowerViaBt(serviceFTMS, stepInfo.targetWatt);
        setCurrentTargetPower(stepInfo.targetWatt);
      }
      // else if (targetSecWatt.length > 0 && elapsedTime > targetSecWatt.length && currentTargetPower != 0) {//finish
      //     setTargetPowerViaBt(serviceFTMS, 50)
      //     setCurrentTargetPower(0)
      //     setIndexStep(indexStep + 1)
      //     setStepIndex(indexStep + 1) //use in other state
      // }
    }
  }, [timerSec, powerInfo, startTime, steps, targetSecWatt, lastUpdate]);

  useEffect(() => {
    if (!pause) {
      const currentTime = Date.now();
      const elapsedTime = Math.floor(
        (currentTime - startTime - totTimePause) / 1000
      ); // Temps en secondes
      const stepInfo = calculateTargetWatt(elapsedTime);
      if (elapsedTime >= count) {
        setChartData((prevChartData) => ({
          labels: [
            ...prevChartData.labels.slice(0, count),
            elapsedTime,
            ...prevChartData.labels.slice(count + 1, targetSecWatt.length),
          ],
          datasets: prevChartData.datasets.map((dataset, index) => {
            const newData =
              index === 0
                ? powerInfo.slidding
                : index === 1
                  ? powerInfo.sliddingCad
                  : index === 2
                    ? stepInfo.targetWatt
                    : currentBPM;
            const newColor = getColor(newData || 0);//not needed
            return {
              ...dataset, //need to copy other config
              borderColor: index != 2 ? [
                ...dataset.borderColor.slice(0, count),
                index === 1
                  ? "rgba(150, 150, 150, 1)"
                  : index === 3
                    ? "rgba(200, 100, 100, 1)"
                    : index == 0
                      ? "rgba(75, 192, 192, 0.2)"
                      : newColor, // never here
                ...dataset.borderColor.slice(count + 1, targetSecWatt.length),
              ] : [...dataset.borderColor.slice(0, count),
              ...targetSecWatt.map((tw) => getColor(tw || 0)).slice(count, targetSecWatt.length)],
              backgroundColor: index != 2 ? [
                ...dataset.backgroundColor.slice(0, count),
                index === 1
                  ? "rgba(150, 150, 150, 1)"
                  : index === 3
                    ? "rgba(200, 100, 100, 1)"
                    : index == 0
                      ? "rgba(75, 192, 192, 0.2)"
                      : newColor, //never here
                ...dataset.backgroundColor.slice(
                  count + 1,
                  targetSecWatt.length
                ),
              ] : [...dataset.backgroundColor.slice(0, count),
              ...targetSecWatt.map((tw) => getColor(tw || 0)).slice(count, targetSecWatt.length)],
              //backgroundColor: getColor(newData || 0).replace("1)", "0.5)"),
              data: index !== 2 ? [
                ...dataset.data.slice(0, count),
                newData || 0,
                ...dataset.data.slice(count + 1, targetSecWatt.length),
              ] : [...dataset.data.slice(0, count),
              ...targetSecWatt.slice(count, targetSecWatt.length)], // Utilisez 0 si les données sont NaN
            };
          }),
        }));
        setCount(count + 1);
      }
    }
  }, [powerInfo, startTime, steps, targetSecWatt, lastUpdate]);

  // useEffect(() => {
  //     // This code runs after the `simulate` state updates
  //     if (simulate || isConnected && false) {
  //         console.log("Set pause to true because is connected or simulate mode, waiting for start")
  //         onPause(true);
  //     }
  // }, [simulate, isConnected]);  // Dependency array, runs the effect when `simulate` changes
  const goNextStep = () => {
    console.log("Next step")
    //skipping message as it blocks the flow if not pause
    // not showing at all
    setSoundHasPlayDuringStep(true)
    if (true || !pause || goNextWarning || window.confirm("You will be redirected to the end of this interval, this can be undone and this message won't be shown again. Are you sure ?")) {
      setSteps(steps.map((x, i) => i !== indexStep ? { ...x } : { ...x, duration: totTimeStep + 1 }))
    }
    setGoNextWarning(true)



  }

  useEffect(() => {
    let wakeLock = null;

    const requestWakeLock = async () => {
      if ('wakeLock' in navigator) {
        try {
          wakeLock = await navigator.wakeLock.request('screen');
          wakeLock.addEventListener('release', () => {
            console.log('Wake Lock was released');
          });
          console.log('Wake Lock is active');
        } catch (err) {
          console.error('Failed to acquire wake lock:', err);
        }
      } else {
        console.error('Wake Lock API not supported');
      }
    };

    if ((isConnected || simulate) && isMobile) {
      requestWakeLock();
    }

    // Clean up the wake lock on component unmount or if isConnected changes
    return () => {
      if (wakeLock !== null && isMobile) {
        wakeLock.release().then(() => {
          console.log('Wake Lock was released by cleanup');
        }).catch(err => {
          console.error('Failed to release wake lock:', err);
        });
        wakeLock = null;
      }
    };
  }, [isConnected, simulate, isMobile]);

  const onPause = (p) => {
    if (p) {
      console.log("Pause");
      setPause(true);
      setStartTimeSincePause(Date.now());
      setStartTimeSinceStepPause(Date.now());
      setTargetPowerViaBt(serviceFTMS, 50);
    } else {
      setDisabledPhoneButton(true)
      console.log("Resume");
      setTotTimePause(totTimePause + Date.now() - startTimeSincePause);
      setTotTimePauseStep(
        totTimePauseStep + Date.now() - startTimeSinceStepPause
      );
      setTargetPowerViaBt(serviceFTMS, currentTargetPower);
      setPause(false);
      //subscribeToStatus(serviceFTMS);
    }
  };

  function handleCharacteristicValueChangedBPM(event) {
    const value = event.target.value;
    const heartRate = value.getUint8(1); // Assuming the heart rate is at byte 1
    console.log(`Heart Rate: ${heartRate} BPM`);
    setCurrentBPM(heartRate);
  }

  const connectToBPM = async () => {
    let device;
    if (!navigator.bluetooth) {
      console.error("Web Bluetooth API is not available in this browser.");
      alert(
        "Web Bluetooth API is not available in this browser. Please use Chrome or another supported browser."
      );
      return;
    }
    try {
      console.log("User initiated connection for heart rate service...");
      device = await navigator.bluetooth.requestDevice({
        filters: [
          { services: [0x180d] }, // Heart Rate service
        ],
        optionalServices: [
          0x180d, // Heart Rate service
        ],
      });
      console.log(`Connecting to ${device.name}`);
      const server = await device.gatt.connect();
      console.log("Device connected");

      const service = await server.getPrimaryService(0x180d);
      console.log("Heart Rate service", service);

      setDeviceBPM(device);
      setServiceBPM(service);

      const heartRateMeasurement = await service.getCharacteristic(0x2a37);
      console.log(
        "Heart Rate Measurement characteristic",
        heartRateMeasurement
      );

      // Subscribe to heart rate notifications
      await heartRateMeasurement.startNotifications();
      heartRateMeasurement.addEventListener(
        "characteristicvaluechanged",
        handleCharacteristicValueChangedBPM
      );

      console.log("Subscribed to heart rate notifications");
      setIsBPMConnected(true);
    } catch (error) {
      console.error("Failed to connect:", error);
      setIsBPMConnected(false);
      if (device && device.gatt.connected) {
        try {
          device.gatt.disconnect();
        } catch (e) {
          console.log(
            "Failed to disconnect after error when connecting BPM:",
            e
          );
        }
      }
    }
  };

  const disconnectFromBPM = async () => {
    if (!window.confirm("Disconnect heart rate sensor ?")) {
      return;
    }
    try {
      if (deviceBPM && deviceBPM.gatt.connected) {
        console.log(`Disconnecting from ${deviceBPM.name}...`);

        // Stop notifications and remove event listener
        const heartRateMeasurement = await serviceBPM.getCharacteristic(0x2a37);
        await heartRateMeasurement.stopNotifications();
        deviceBPM.gatt.disconnect();
        heartRateMeasurement.removeEventListener(
          "characteristicvaluechanged",
          handleCharacteristicValueChangedBPM
        );

        console.log("Device disconnected");
        // Reset device and service
        setDeviceBPM(null);
        setServiceBPM(null);
        setIsBPMConnected(false);
        setCurrentBPM(initialStates.currentBPM);
      } else {
        console.log("No device is connected.");
      }
    } catch (error) {
      console.error("Failed to disconnect:", error);
    }
  };
  const connectToDevice = async () => {
    let device;
    if (!navigator.bluetooth) {
      console.error("Web Bluetooth API is not available in this browser.");
      alert(
        "Web Bluetooth API is not available in this browser. Please use Chrome or another supported browser."
      );
      return;
    }
    try {
      console.log("User initiated connection...");
      device = await navigator.bluetooth.requestDevice({
        filters: [
         { services: [0x1826]},
         { services: [0x1818]}
         //Include known service UUIDs
        ],//0x1816
        //acceptAllDevices: true,
        optionalServices: [0x1826, 0x2ad9, 0x1818],
      });
      console.log(`Connecting to ${device.name}, id = ${device.id} : ${device.uuids}`);
      const server = await device.gatt.connect();
      console.log("Device connected");
      const serviceFTMS = await server.getPrimaryService(0x1826);
      console.log("serviceFTMS", serviceFTMS);
      const serviceCP = await server.getPrimaryService(0x1818);
      console.log("serviceCP", serviceCP);

      setDeviceHT(device);
      setServiceFTMS(serviceFTMS);
      setServiceCP(serviceCP);
      const controlPointUuid = 0x2ad9; // Fitness Machine Control Point
      try {
        const controlPoint = await serviceFTMS.getCharacteristic(
          controlPointUuid
        );
        controlPoint.addEventListener(
          "characteristicvaluechanged",
          handleIndications
        );
        await controlPoint.startNotifications();
        const buffer = new ArrayBuffer(1); // Buffer for the OpCode
        const dataView = new DataView(buffer);

        // OpCode for Request Control
        dataView.setUint8(0, 0x00); // Assuming 0x00 is the OpCode for Request Control
        console.log("Sending Control request to", controlPoint);
        await controlPoint.writeValue(buffer); //needed ?
        console.log("Control request sent.");
      } catch (error) {
        console.error("Failed to request control", error);
      }
      await subscribeToMetric(serviceCP);
      setIsConnected(true);
      console.log("setting set is connected te true");
      onConnect(device);
    } catch (error) {
      console.error("Failed to connect:", error);
      onDisconnect();
      setIsConnected(false);
      try {
        device.gatt.disconnect();
      } catch (e) {
        console.log("Failed to disconnect after error when connecting:", e);
      }
    }
  };

  const disconnectFromDevice = async () => {
    try {
      if (!window.confirm("Disconnect home trainer ?")) {
        return;
      }
      console.log("User initiated disconnection...");

      if (deviceHT) {
        if (serviceFTMS) {
          try {
            const controlPointUuid = 0x2ad9;
            const characteristic = await serviceFTMS.getCharacteristic(
              controlPointUuid
            );
            await characteristic.stopNotifications();
            characteristic.removeEventListener(
              "characteristicvaluechanged",
              handleIndications
            );
            console.log("Stopped notifications for FTMS service.");
          } catch (error) {
            console.error(
              "Failed to stop notifications for FTMS service:",
              error
            );
          }
        }

        if (serviceCP) {
          try {
            const characteristic = await serviceCP.getCharacteristic(0x2a63);
            await characteristic.stopNotifications();
            characteristic.removeEventListener(
              "characteristicvaluechanged",
              handleCharacteristicValueChanged
            );
            console.log("Stopped notifications for CP service.");
            setCurrentCadence(initialStates.currentCadence);
            setCurrentPower(initialStates.currentPower);
          } catch (error) {
            console.error(
              "Failed to stop notifications for CP service:",
              error
            );
          }
        }

        await deviceHT.gatt.disconnect();
        console.log("Device disconnected.");
      } else {
        console.log("No device is connected.");
      }

      setDeviceHT(null);
      setServiceFTMS(null);
      setServiceCP(null);
      setIsConnected(false);
      onDisconnect();
    } catch (error) {
      console.error("Failed to disconnect:", error);
    }
  };

  let previousTimestamp = null;
  let previousCrankRevolutions = null;

  function handleCharacteristicValueChanged(event) {
    const value = event.target.value;
    // Convert DataView to an array of bytes for storage
    const buffer = new ArrayBuffer(value.byteLength);
    const view = new Uint8Array(buffer);
    for (let i = 0; i < value.byteLength; i++) {
      view[i] = value.getUint8(i);
    }
    setCurrentHTValue(view); //store for debug

    const flags = value.getUint16(0, true); // Read flags field
    // Instantaneous Power field (always present, INT16)
    const instantaneousPower = value.getInt16(2, true);
    setLastUpdate(Date.now());
    console.log(`Instantaneous Power: ${instantaneousPower} Watts`);
    setCurrentPower(instantaneousPower);

    // Check for other optional data based on flags
    // Example: Check if Crank Revolution Data is present (bit 5)
    if (flags & (1 << 5)) {
      // Bitwise to check if the 6th bit is set
      let crankRevolutions, lastCrankEventTime;
      if (value.byteLength === 14) {
        // Use offsets for a 14-byte array
        crankRevolutions = value.getUint16(10, true);
        lastCrankEventTime = value.getUint16(12, true);
      } else if (value.byteLength === 16) {
        // Use offsets for a 16-byte array
        crankRevolutions = value.getUint16(12, true);
        lastCrankEventTime = value.getUint16(14, true);
      } else {
        crankRevolutions = value.getUint16(10, true); //testing that, Suito initial
        lastCrankEventTime = value.getUint16(12, true);
        console.error("Unsupported value length:", value.byteLength);
      }

      if (previousCrankRevolutions != null && previousTimestamp != null) {
        const timeDifference = (lastCrankEventTime - previousTimestamp) / 1024; // Convert to seconds
        const revolutionsDifference =
          crankRevolutions - previousCrankRevolutions;

        if (revolutionsDifference > 0 || Date.now() - lastUpdate >= 3000) {
          // Basically if not even one rotation in 3 sec, RMP drop to zero.
          const cadence = (revolutionsDifference / timeDifference) * 60; // RPM
          console.log(`Cadence: ${cadence.toFixed(2)} RPM`);
          setCurrentCadence(Math.round(cadence) >= 0 ? Math.round(cadence) : 0);
          setLastUpdate(Date.now());
          previousTimestamp = lastCrankEventTime;
          previousCrankRevolutions = crankRevolutions;
        }
      } else {
        previousTimestamp = lastCrankEventTime;
        previousCrankRevolutions = crankRevolutions;
      }
    }

    // You can add similar checks and calculations for other data based on other bits
  }

  async function subscribeToMetric(service) {
    // Get the Cycling Power Measurement characteristic
    const characteristic = await service.getCharacteristic(0x2a63);

    // Start listening for updates from the characteristic
    await characteristic.startNotifications();

    // Add the event listener for when the characteristic value changes
    characteristic.addEventListener(
      "characteristicvaluechanged",
      handleCharacteristicValueChanged
    );
  }

  function handleStatusUpdate(event) {
    const value = event.target.value;
    const status = value.getUint8(0);
    console.log(`Machine status update: ${status}`);
  }

  function handleIndications(event) {
    const value = event.target.value; // process your incoming data
    console.log("Received indication:", value);
  }

  async function setTargetPowerViaBt(service, watts) {
    if (!simulate && service) {
      const controlPointUuid = 0x2ad9;
      const buffer = new ArrayBuffer(3);
      const dataView = new DataView(buffer);
      dataView.setUint8(0, 0x05); // OpCode for Set Target Power
      dataView.setInt16(1, parseInt(Math.round(watts)), true); // Target watts as SINT16, little-endian
      const controlPoint = await service.getCharacteristic(controlPointUuid);
      await controlPoint.writeValue(buffer);
      console.log(
        `Target power set to ${watts} watts to control`,
        controlPoint
      );
    }
    setCurrentERG(watts);
  }


  function formatTime(seconds) {
    let reverse = false;
    if (seconds < 0) {
      seconds = -seconds;
      reverse = true;
    }
    const minutes = Math.floor(seconds / 60); // Get the full minutes
    const remainingSeconds = seconds % 60; // Get the remaining seconds
    // Return the formatted time string
    // Add a leading zero to seconds if less than 10
    const timeStr = reverse
      ? `- ${minutes}'${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`
      : `${minutes}'${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
    return timeStr;
  }



  const colorTimerTot = "rgba(0,0,65,0.7)";
  const colorTimerLap = "rgba(0,0,65,0.7)";
  const colorBPM = "rgba(0,0,65,0.7)";
  const colorWAvgLap = "rgba(0,0,65,0.7)";
  const colorWAvg = "rgba(0,0,65,0.7)";
  const colorW = "rgba(0,0,65,0.7)";
  const colorCad = "rgba(0,0,65,0.7)";

  function calculateFTPDuringTest() {
    if (
      infoToSave.selectedPlan.username == "Test" &&
      infoToSave.selectedPlan.name == "20 min" &&
      !infoToSave.isModificationMade
    ) {
      if (powerInfo.avgSteps.length >= 8) {
        return Math.round(powerInfo.avgSteps[7] * 0.95);
      } else {
        return 0;
      }
    } else if (
      infoToSave.selectedPlan.username == "Test" &&
      infoToSave.selectedPlan.name == "1 hour" &&
      !infoToSave.isModificationMade
    ) {
      if (powerInfo.avgSteps.length >= 8) {
        return Math.round(powerInfo.avgSteps[7]);
      } else {
        return 0;
      }
    } else if (
      infoToSave.selectedPlan.username == "Test" &&
      infoToSave.selectedPlan.name == "Ramp Up" &&
      !infoToSave.isModificationMade
    ) {
      if (powerInfo.avgSteps.length >= 8) {
        return Math.round(powerInfo.bestMinuteW * 0.75);
      } else {
        return 0;
      }
    } else {
      return null;
    }
  }
  const connectionIssueDiv = (
    <div>
      <ul>
        <li>
          Ensure your trainer uses Bluetooth (not ANT+) and that Bluetooth is
          activated on your device
        </li>
        <li>
          Ensure you are using a compatible browser (such as Chrome, not Safari)
        </li>
        <li>
          Ensure your trainer is not connected to another device (phone,
          computer, ...)
        </li>
        <li>Try restarting your trainer</li>
        <li>
          Try restarting your computer (strangely, this often resolves the
          issue)
        </li>
        <li>
          If you can connect your trainer but no data is received, try
          disconnecting and reconnecting
        </li>
        <li>
          Send feedback with your trainer's brand and model, along with your
          device and browser type
        </li>
      </ul>
    </div>
  );
  if (!isMobile) {
    return (
      <div>
        {!isConnected && !simulate && false ? ( //legacy
          <div>
            <Button onClick={connectToDevice} type="primary">
              <LinkOutlined /> Trainer
            </Button>
            <Button onClick={simulateTrainer} style={{ marginLeft: 8 }}>
              Simulate
            </Button>
          </div>
        ) : (
          <div>
            <Row gutter={4}>
              <Popover
                title="Trouble Connecting Trainer to Computer?"
                content={connectionIssueDiv}
              >
                <Col span={3}>
                  <Button
                    block
                    danger={isConnected || simulate ? true : false}
                    onClick={
                      isConnected || simulate
                        ? disconnectFromDevice
                        : connectToDevice
                    }
                    disabled={!pause}
                    type={isConnected || simulate ? "default" : "primary"}
                  >
                    {simulate || isConnected ? (
                      <DisconnectOutlined />
                    ) : (
                      <ApiOutlined />
                    )}
                    Trainer
                    {/*<img src={logo_white} style={{ height: "30px", marginTop: "3%" }} alt="SimplyRide.app" />*/}
                  </Button>
                </Col>
              </Popover>
              <Col span={3}>
                <Button
                  block
                  danger={isBPMConnected || simulate ? true : false}
                  onClick={
                    isBPMConnected || simulate ? disconnectFromBPM : connectToBPM
                  }
                  disabled={!pause}
                  type={isBPMConnected || simulate ? "default" : "primary"}
                >
                  {simulate || isBPMConnected ? (
                    <DisconnectOutlined />
                  ) : (
                    <ApiOutlined />
                  )}{" "}
                  <HeartOutlined />
                </Button>
              </Col>
              <Col span={1}>
                <DownSquareFilled
                  className="custom-tag"
                  onClick={() => {
                    if (additionalPower + FTP > 20) {
                      setAdditionalPower((prev) => (prev - 1))
                      setSteps(steps.map((x) => ({ ...x, watt: Math.round((x.watt / FTP) * (FTP - 1 + additionalPower)) })))
                    }
                  }}
                />
              </Col>
              <Col span={5}>
                <Popover
                  content={
                    "You can easily adjust the target FTP."
                  }
                  title="Info"
                >
                  <Tag block className="custom-tag" color="grey">
                    {`FTP : ${FTP + additionalPower}W (${FTP})`}
                  </Tag>
                </Popover>
              </Col>
              <Col span={1}>
                <UpSquareFilled
                  className="custom-tag"
                  onClick={() => {

                    setAdditionalPower((prev) => (prev + 1))
                    setSteps(steps.map((x) => ({ ...x, watt: Math.round((x.watt / FTP) * (FTP + 1 + additionalPower)) })))

                  }}
                />
              </Col>
              <Col span={2}>
                <Button
                  block
                  disabled={!pause || (!isConnected && !simulate)}
                  onClick={() => onPause(false)}
                  type="primary"
                >
                  <PlaySquareOutlined />
                </Button>
              </Col>
              <Col span={2}>
                <Button
                  block
                  disabled={pause || (!isConnected && !simulate)}
                  onClick={() => onPause(true)}
                  type="primary"
                >
                  <PauseOutlined />
                </Button>
              </Col>
              <Col span={2}>
                <Button
                  block
                  disabled={!pause || (!isConnected && !simulate)}
                  onClick={() => {
                    window.confirm("This will reset your training") &&
                      setReset(reset + 1);
                  }}
                  type="default"
                  danger
                >
                  <RollbackOutlined />
                </Button>
              </Col>
              <Col span={2}>
                <Popover content="This will skip this interval step, this can be undone !">
                  <Button
                    block
                    danger
                    disabled={indexStep >= steps.length || steps[indexStep].duration - totTimeStep <= 2 || (!isConnected && !simulate)}
                    onClick={() => goNextStep()}
                    type="primary"
                  >
                    <FastForwardOutlined />
                  </Button>
                </Popover>
              </Col>
              <Col span={isAdmin ? 2 : 3}>
                <SaveTraining
                  disabled={!pause || (!isConnected && !simulate)}
                  weight={weight}
                  steps={steps}
                  initSteps={initSteps}
                  data={powerInfo}
                  FTP={FTP}
                  setFTP={setFTP}
                  setWeight={setWeight}
                  infoToSave={infoToSave}
                  FTPResult={calculateFTPDuringTest()}
                  otherUserInfo={otherUserInfo}
                  setOtherUserInfo={setOtherUserInfo}
                />
              </Col>

              {isAdmin && (
                <Col span={1}>
                  <Button
                    hidden={isAdmin}
                    onClick={() => {
                      setSimulate(true);
                      simulateTrainer();
                    }}
                    disabled={isConnected || simulate}
                  >
                    <RobotOutlined />
                  </Button>
                </Col>
              )}

            </Row>
            <p />
            <Row>
              <Col span={3}>
                <Tag color={colorTimerTot} className="custom-tag">
                  {formatTime(totTimeFinal - totTime)}
                </Tag>
              </Col>
              <Col span={1}></Col>
              <Col span={16}>
                <Progress percent={Math.round((totTime * 100) / totTimeFinal)} />
              </Col>
              <Col span={1}></Col>
              <Col span={3}>
                <Tag color={colorTimerTot} className="custom-tag">
                  <FieldTimeOutlined /> {formatTime(totTime)}
                </Tag>
              </Col>
            </Row>

            {/* <p />
                    <Row><Col span={12}><Tag color={getColor(currentTargetPower)} className="custom-tag">{indexStep + 1}. ERG {currentERG == currentTargetPower ? currentERG : `${ currentERG }(target : ${ currentTargetPower })`} W for {indexStep < steps.length && indexStep >= 0 ? formatTime(steps[indexStep].duration - totTimeStep) : "0'00"}</Tag></Col>
                        <Col span={0}></Col>
                        <Col span={4}><DownSquareFilled className="custom-tag" onClick={() => currentERG > 10 && setAdditionalPower(additionalPower - 10)} /></Col>
                        <Col span={4}>
                            <Popover content={"You can easily ajust the ERG power of your trainer without changing the actual plan."} title="Info">
                                <Tag className="custom-tag" color="grey">{additionalPower < 0 ? additionalPower : `+ ${ additionalPower }`} W</Tag>
                            </Popover>
                        </Col>
                        <Col span={4}><UpSquareFilled className="custom-tag" onClick={() => setAdditionalPower(additionalPower + 10)} /></Col>
                    </Row> */}
            <Row>
              <Col span={10}>
                <PowerGauge
                  maxPower={max(steps.map((s) => s.watt))}
                  remainingTime={
                    indexStep < steps.length && indexStep >= 0
                      ? steps[indexStep].duration - totTimeStep
                      : 0
                  }
                  nextTargetPower={nextTargetPower}
                  targetPower={currentTargetPower ? currentTargetPower : 0}
                  currentPower={Math.round(powerInfo.slidding)}
                  isLast={indexStep === steps.length - 1}
                  paused={pause}
                />
              </Col>
              <Col span={14}>
                <p />
                <Row>
                  <Col span={24}>
                    <Row>
                      <Col span={5}>
                        <Tag
                          color={remainingTimeStep >= 5 ? "green" : "red"}
                          className="custom-tag"
                        >
                          <b style={{ fontSize: "20px" }}>
                            {" "}
                            {remainingTimeStep >= 5 ? (
                              <HourglassOutlined />
                            ) : (
                              <AlertOutlined />
                            )}{" "}
                            {formatTime(remainingTimeStep)}
                          </b>
                        </Tag>
                      </Col>
                      <Col span={1}></Col>
                      <Col span={13}>
                        <Progress
                          percent={Math.round(
                            (totTimeStep * 100) /
                            (totTimeStep + remainingTimeStep)
                          )}
                        />
                      </Col>
                      <Col span={1}></Col>
                      <Col span={4}>
                        <Tag
                          color={remainingTimeStep >= 5 ? "green" : "red"}
                          className="custom-tag"
                        >
                          {formatTime(totTimeStep)}
                        </Tag>
                      </Col>
                    </Row>
                    <p />
                    <div
                      className="screen-div"
                      style={{
                        color: "rgba(0,0,65,1)",
                        backgroundColor: "rgba(0,0,65,0.1)",
                        border: `5px solid ${getColor(currentTargetPower)}`,
                      }}
                    >
                      <span style={{ fontSize: "25px" }}>
                        {indexStep + 1} / {steps.length} &nbsp;&nbsp; ➵ 𖦏{" "}
                        {currentTargetPower || 0} W{" "}
                      </span>
                      <p style={{ marginTop: "-10px" }} />
                      {indexStep < steps.length - 1 && remainingTimeStep < 5 ? (
                        <b style={{ fontSize: "30px", color: getWarningColor() }}>
                          {" "}
                          {Math.round(powerInfo.slidding)} W{" "}
                          {remainingTimeStep < 5 && nextTargetPower && (
                            <NotificationOutlined />
                          )}{" "}
                          {remainingTimeStep < 5 &&
                            nextTargetPower}{" "}
                          W{" "}
                        </b>
                      ) : (
                        <b style={{ fontSize: "30px", color: getWarningColor() }}>
                          {Math.round(powerInfo.slidding) <
                            currentTargetPower * 0.9 ? (
                            <ForwardOutlined rotate={-90} />
                          ) : Math.round(powerInfo.slidding) >
                            currentTargetPower * 1.1 ? (
                            <ForwardOutlined rotate={90} />
                          ) : (
                            " "
                          )}{" "}
                          <ThunderboltOutlined />{" "}
                          <b>{Math.round(powerInfo.slidding)}</b> W{" "}
                          {Math.round(powerInfo.slidding) <
                            currentTargetPower * 0.9 ? (
                            <ForwardOutlined rotate={-90} />
                          ) : Math.round(powerInfo.slidding) >
                            currentTargetPower * 1.1 ? (
                            <ForwardOutlined rotate={90} />
                          ) : (
                            <CheckOutlined />
                          )}
                        </b>
                      )}
                      <p style={{ marginTop: "-10px" }} />{" "}
                      <Loading3QuartersOutlined
                        reversed={true}
                        spin={
                          steps.length > 0 &&
                          indexStep < steps.length &&
                          indexStep >= 0 &&
                          steps[indexStep].comment &&
                          extractRPM(steps[indexStep].comment) != null
                        }
                      />{" "}
                      {steps.length == 0 ||
                        indexStep >= steps.length ||
                        steps[indexStep].duration - totTimeStep < 0 ||
                        !steps[indexStep].comment ||
                        extractRPM(steps[indexStep].comment) == null
                        ? "No target rpm"
                        : Math.round(powerInfo.sliddingCad) == null ||
                          Math.round(powerInfo.sliddingCad) < 10 ||
                          Math.round(powerInfo.sliddingCad) > 300
                          ? "No valid rpm"
                          : Math.round(powerInfo.sliddingCad) >
                            extractRPM(steps[indexStep].comment) + 5
                            ? "Spin slower! " +
                            Math.round(powerInfo.sliddingCad) +
                            " > " +
                            extractRPM(steps[indexStep].comment) +
                            " rpm"
                            : Math.round(powerInfo.sliddingCad) <
                              extractRPM(steps[indexStep].comment) - 5
                              ? "Spin faster! " +
                              Math.round(powerInfo.sliddingCad) +
                              " < " +
                              extractRPM(steps[indexStep].comment) +
                              " rpm"
                              : "Keep spinning that way! "}
                      <p style={{ marginTop: "-10px" }} />{" "}
                      {steps.length == 0
                        ? "No training"
                        : indexStep >= steps.length ||
                          steps[indexStep].duration - totTimeStep < 0
                          ? "Training over, well done!"
                          : steps[indexStep].comment
                            ? steps[indexStep].comment
                            : "🚲"}{" "}
                      {pause && " (Paused)"}
                    </div>
                    <p />
                    {/* <Tag color="blue" className="custom-tag">Instant W {currentPower}</Tag>
                                    <p /> */}
                    {calculateFTPDuringTest() != null && (
                      <>
                        {" "}
                        <Row>
                          <Col span={24}>
                            <Tag color="red" className="custom-tag">
                              Estimated FTP : {calculateFTPDuringTest()} W
                            </Tag>
                          </Col>
                        </Row>
                        <p />
                      </>
                    )}

                    <Row>
                      <Col span={11}>
                        <Tag color={colorWAvg} className="custom-tag">
                          <ThunderboltOutlined /> Avg{" "}
                          {Math.round(powerInfo.avgTot)}
                        </Tag>
                      </Col>
                      <Col span={2}></Col>
                      <Col span={11}>
                        <Tag color={colorWAvgLap} className="custom-tag">
                          <ThunderboltOutlined /> Avg Lap{" "}
                          {Math.round(powerInfo.avgSteps.slice(-1))}
                        </Tag>
                      </Col>
                    </Row>
                    <p />
                    <Row>
                      <Col span={11}>
                        <Tag color={colorCad} className="custom-tag">
                          <Loading3QuartersOutlined spin={true} reversed={true} />{" "}
                          RPM {powerInfo.sliddingCad < 300 && powerInfo.sliddingCad > 10 ? Math.round(powerInfo.sliddingCad) : "0"}
                        </Tag>
                      </Col>
                      <Col span={2}></Col>
                      <Col span={11}>
                        <Tag color={colorBPM} className="custom-tag">
                          <HeartOutlined /> BPM {Math.round(currentBPM)}
                        </Tag>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            {/*<Row>
                        <Row>
                            <PlaneGame targetPower={currentERG} currentPower={powerInfo.slidding || 0} />
                                </Row>
                    </Row>*/}
            <Line
              style={{ marginTop: calculateFTPDuringTest() == null ? -20 : -20 }}
              data={chartData}
              options={{
                responsive: true,
                animation: false,
                scales: {
                  x: {
                    title: {
                      display: true,
                      text: "Time (sec)",
                    },
                  },
                  y: {
                    beginAtZero: true,
                    title: {
                      display: true,
                      text: "Watts x RPM x BPM",
                    },
                  },
                },
              }}
            />
          </div>
        )}
      </div>
    );
  }
  else {
    return (
      <div>
        {!isConnected && !simulate && false ? ( //legacy
          <div>

            <Button onClick={connectToDevice} type="primary"><LinkOutlined /> Trainer</Button>
            <Button onClick={simulateTrainer} style={{ marginLeft: 8 }}>Simulate</Button>
          </div>
        ) : (
          <div>
            <Modal title={infoToSave.selectedPlan.display_name} open={modalPlanInfoIsOpen} cancelText={"Ok"} cancelType="default" okButtonProps={{ style: { display: 'none' } }} width="90hh" onCancel={() => setModalPlanInfoIsOpen(false)}>
              <Text>{infoToSave.selectedPlan.description}</Text><p />
              <Text>{steps.length} steps, duration: <strong>{formatTime((steps.map(s => s.duration).reduce(
                (accumulator, currentValue) => accumulator + currentValue,
                0,
              )))}</strong>, Avg Power: <strong>{steps.length == 0 ? 0 : Math.round(steps.map(s => s.duration * s.watt).reduce(
                (accumulator, currentValue) => accumulator + currentValue,
                0,
              ) / (steps.map(s => s.duration).reduce(
                (accumulator, currentValue) => accumulator + currentValue,
                0,
              )))} Watts</strong></Text>
              <p />
              <TrainingChart steps={steps} FTP={FTP} isMobile={true} />
              <p />
              <HomeTrainerProgress steps={steps} FTP={FTP} currentIndex={-1} />

            </Modal >
            <Row gutter={5}>
              <Col span={3}><Button block danger={isConnected || simulate ? true : false} onClick={isConnected || simulate ? disconnectFromDevice : connectToDevice} disabled={!pause} type={isConnected || simulate ? "default" : "primary"}>
                {simulate || isConnected ? <DisconnectOutlined /> : <ApiOutlined />}Trainer</Button></Col>
              <Col span={2}><Button block danger={isBPMConnected || simulate ? true : false} onClick={isBPMConnected || simulate ? disconnectFromBPM : connectToBPM} disabled={!pause} type={isBPMConnected || simulate ? "default" : "primary"}>
                {simulate || isBPMConnected ? <DisconnectOutlined /> : <ApiOutlined />}<HeartOutlined /></Button></Col>
              <Col span={1}>
                <DownSquareFilled
                  className="custom-tag"
                  onClick={() => {
                    if (additionalPower + FTP > 20) {
                      setAdditionalPower((prev) => (prev - 10))
                      setSteps(steps.map((x) => ({ ...x, watt: Math.round((x.watt / FTP) * (FTP - 10 + additionalPower)) })))
                    }
                  }}
                />
              </Col>
              <Col span={5}>
                <Popover
                  content={
                    "You can easily adjust the target FTP."
                  }
                  title="Info"
                >
                  <Tag block className="custom-tag" color="grey">
                    {`FTP : ${FTP + additionalPower}W`}
                  </Tag>
                </Popover>
              </Col>

              <Col span={1}>
                <UpSquareFilled
                  className="custom-tag"
                  onClick={() => {

                    setAdditionalPower((prev) => (prev + 10))
                    setSteps(steps.map((x) => ({ ...x, watt: Math.round((x.watt / FTP) * (FTP + 10 + additionalPower)) })))

                  }}
                />
              </Col>
              <Col span={3}>
                <Tag color={colorTimerTot} className="custom-tag-phone">{formatTime(totTimeFinal - totTime)}</Tag>
              </Col>
              <Col span={6} style={{ padding: "5px" }}>
                <Progress percent={Math.round(totTime * 100 / totTimeFinal)} />
              </Col>
              <Col span={3}>
                <Tag color={colorTimerTot} className="custom-tag-phone">
                  <FieldTimeOutlined />  {formatTime(totTime)}</Tag>
              </Col>
            </Row>
            <p />
            <Row gutter={5}>
              <Col span={6}><Button block disabled={!pause || (!isConnected && !simulate)} onClick={() => onPause(false)} type="primary"><PlaySquareOutlined /></Button></Col>
              <Col span={6}><Button block disabled={pause || (!isConnected && !simulate)} onClick={() => onPause(true)} type="primary" ><PauseOutlined /></Button></Col>
              <Col span={4}><Button block disabled={!pause || (!isConnected && !simulate)} onClick={() => { window.confirm('This will reset your training') && setReset(reset + 1); }} type="default" danger ><RollbackOutlined /></Button></Col>
              <Col span={4}>
                <Popover content="This will skip this interval step, this can be undone !">
                  <Button
                    block
                    danger
                    disabled={indexStep >= steps.length || steps[indexStep].duration - totTimeStep <= 2 || (!isConnected && !simulate)}
                    onClick={() => goNextStep()}
                    type="primary"
                  >
                    <FastForwardOutlined />
                  </Button>
                </Popover>
              </Col>
              <Col span={isAdmin ? 2 : 4}><SaveTraining disabled={!pause || (!isConnected && !simulate)} weight={weight} steps={steps} initSteps={initSteps} data={powerInfo} FTP={FTP} setFTP={setFTP} setWeight={setWeight} infoToSave={infoToSave} FTPResult={calculateFTPDuringTest()} otherUserInfo={otherUserInfo} setOtherUserInfo={setOtherUserInfo} /></Col>
              {isAdmin && <Col span={2}><Button block onClick={() => { setSimulate(true); simulateTrainer() }} disabled={isConnected || simulate} ><RobotOutlined /></Button></Col>}
            </Row>
            <p />
            <Row>

              <Col span={24}>
                <Row>
                  <Col span={24}>
                    <Row><Col span={6}>
                      <Tag color={remainingTimeStep >= 5 ? "green" : "red"} className="custom-tag-phone"><b style={{ fontSize: "20px" }}> {remainingTimeStep >= 5 ? <HourglassOutlined /> : <AlertOutlined />} {formatTime(remainingTimeStep)}</b></Tag></Col><Col span={1}></Col><Col span={10}><Progress percent={Math.round(totTimeStep * 100 / (totTimeStep + remainingTimeStep))} /></Col><Col span={1}></Col><Col span={6}><Tag color={remainingTimeStep >= 5 ? "green" : "red"} className="custom-tag-phone">{formatTime(totTimeStep)}</Tag></Col></Row>
                    <p />

                    <Row gutter={16}>
                      <Col span={18}>

                        <Row>
                          <Col span={24}>
                            <div
                              className="screen-div"
                              style={{
                                color: "rgba(0,0,65,1)",
                                backgroundColor: "rgba(0,0,65,0.1)",
                                border: `5px solid ${getColor(currentTargetPower)}`,
                              }}
                            >
                              <span style={{ fontSize: "20px" }}>
                                {indexStep + 1} / {steps.length} &nbsp;&nbsp; ➵ 𖦏{" "}
                                {currentTargetPower || 0} W{" "}
                              </span>
                              <p style={{ marginTop: "-15px" }} />
                              {indexStep < steps.length - 1 && remainingTimeStep < 5 ? (
                                <b style={{ fontSize: "20px", color: getWarningColor() }}>
                                  {" "}
                                  {Math.round(powerInfo.slidding)} W{" "}
                                  {remainingTimeStep < 5 && nextTargetPower && (
                                    <NotificationOutlined />
                                  )}{" "}
                                  {remainingTimeStep < 5 &&
                                    nextTargetPower &&
                                    nextTargetPower}{" "}
                                  W{" "}
                                </b>
                              ) : (
                                <b style={{ fontSize: "20px", color: getWarningColor() }}>
                                  {Math.round(powerInfo.slidding) <
                                    currentTargetPower * 0.9 ? (
                                    <ForwardOutlined rotate={-90} />
                                  ) : Math.round(powerInfo.slidding) >
                                    currentTargetPower * 1.1 ? (
                                    <ForwardOutlined rotate={90} />
                                  ) : (
                                    " "
                                  )}{" "}
                                  <ThunderboltOutlined />{" "}
                                  <b>{Math.round(powerInfo.slidding)}</b> W{" "}
                                  {Math.round(powerInfo.slidding) <
                                    currentTargetPower * 0.9 ? (
                                    <ForwardOutlined rotate={-90} />
                                  ) : Math.round(powerInfo.slidding) >
                                    currentTargetPower * 1.1 ? (
                                    <ForwardOutlined rotate={90} />
                                  ) : (
                                    <CheckOutlined />
                                  )}
                                </b>
                              )}
                              <p style={{ marginTop: "-15px" }} />{" "}
                              <Loading3QuartersOutlined
                                reversed={true}
                                spin={
                                  steps.length > 0 &&
                                  indexStep < steps.length &&
                                  indexStep >= 0 &&
                                  steps[indexStep].comment &&
                                  extractRPM(steps[indexStep].comment) != null
                                }
                              />{" "}
                              {steps.length == 0 ||
                                indexStep >= steps.length ||
                                steps[indexStep].duration - totTimeStep < 0 ||
                                !steps[indexStep].comment ||
                                extractRPM(steps[indexStep].comment) == null
                                ? "No target rpm"
                                : Math.round(powerInfo.sliddingCad) == null ||
                                  Math.round(powerInfo.sliddingCad) < 10 ||
                                  Math.round(powerInfo.sliddingCad) > 300
                                  ? "No valid rpm"
                                  : Math.round(powerInfo.sliddingCad) >
                                    extractRPM(steps[indexStep].comment) + 5
                                    ? "Spin slower! " +
                                    Math.round(powerInfo.sliddingCad) +
                                    " > " +
                                    extractRPM(steps[indexStep].comment) +
                                    " rpm"
                                    : Math.round(powerInfo.sliddingCad) <
                                      extractRPM(steps[indexStep].comment) - 5
                                      ? "Spin faster! " +
                                      Math.round(powerInfo.sliddingCad) +
                                      " < " +
                                      extractRPM(steps[indexStep].comment) +
                                      " rpm"
                                      : "Keep spinning that way! "}
                              <p style={{ marginTop: "-15px" }} />{" "}
                              {steps.length == 0
                                ? "No training"
                                : indexStep >= steps.length ||
                                  steps[indexStep].duration - totTimeStep < 0
                                  ? "Training over, well done!"
                                  : steps[indexStep].comment
                                    ? steps[indexStep].comment
                                    : "🚲"}{" "}
                              {pause && " (Paused)"}
                            </div>
                          </Col>
                        </Row><p />{calculateFTPDuringTest() != null && <> <Row>
                          <Col span={24}>
                            <Tag color="red" className="custom-tag">Estimated FTP : {calculateFTPDuringTest()} W</Tag>
                          </Col>
                        </Row>
                          <p /></>}<Row gutter={5}>
                          <Col span={6}>
                            <Tag color={colorWAvg} className="custom-tag-phone"><ThunderboltOutlined /> Avg {Math.round(powerInfo.avgTot)}</Tag>
                          </Col>
                          <Col span={6}>
                            <Tag color={colorWAvgLap} className="custom-tag-phone"><ThunderboltOutlined /> Avg Lap {Math.round(powerInfo.avgSteps.slice(-1))}</Tag>
                          </Col>
                          <Col span={6}>
                            <Tag color={colorCad} className="custom-tag-phone"><Loading3QuartersOutlined spin={true} reversed={true} /> RPM {powerInfo.sliddingCad < 300 && powerInfo.sliddingCad > 10 ? Math.round(powerInfo.sliddingCad) : "0"}
                            </Tag>
                          </Col>
                          <Col span={6}>
                            <Tag color={colorBPM} className="custom-tag-phone"><HeartOutlined /> BPM  {Math.round(currentBPM)}</Tag>
                          </Col>
                        </Row>
                      </Col>

                      <Col span={6} ><HomeTrainerProgress FTP={FTP} currentIndex={indexStep} steps={steps} isMobile={true} /></Col>


                    </Row >
                    <p />

                    {/* <Tag color="blue" className="custom-tag-phone">Instant W {currentPower}</Tag>
                                  <p /> 
                                  <Row>
                                      <Col span={5}>
                                          <Tag color={colorWAvg} className="custom-tag-phone"><ThunderboltOutlined /> Avg {Math.round(powerInfo.avgTot)}</Tag>
                                      </Col>
                                      <Col span={1}></Col>
                                      <Col span={5}>
                                          <Tag color={colorWAvgLap} className="custom-tag-phone"><ThunderboltOutlined /> Avg Lap {Math.round(powerInfo.avgSteps.slice(-1))}</Tag>
                                      </Col>
                                      <Col span={2}></Col>
                                      <Col span={5}>
                                          <Tag color={colorCad} className="custom-tag-phone"><Loading3QuartersOutlined spin={true} reversed={true} /> RPM {currentCadence || 0}</Tag>
                                      </Col>
                                      <Col span={1}></Col>
                                      <Col span={5}>
                                          <Tag color={colorBPM} className="custom-tag-phone"><HeartOutlined /> BPM NaN</Tag>
                                      </Col>
                                  </Row>*/}


                  </Col>
                </Row >
              </Col >
            </Row>

          </div >
        )
        }
      </div >
    );
  }
}

export default HomeTrainer;
