import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, Switch } from 'antd';

function TrainingForm({ steps, setSteps, duplicateSelectedSteps, deleteSelectedSteps, setIsModificationMade, createWarmUpSteps }) {
    const [duration, setDuration] = useState(null);
    const [watt, setWatt] = useState(null);
    const [fromWatt, setFromWatt] = useState(null);
    const [toWatt, setToWatt] = useState(null);
    //const [stepWatt, setStepWatt] = useState(null);
    const [comment, setComment] = useState('');
    const [warmUp, setWarmUp] = useState(false);
    const [addingMode, setAddingMode] = useState("add");


    const handleSubmit = (event) => {

        const minStepDuration = 10;
        let newSteps;
        if (!warmUp) {
            newSteps = [];

            if (!duration || !watt || duration < minStepDuration) {
                alert(`Duration (>=${minStepDuration} secs) and average watts are required!`);
                return;
            }
            newSteps.push({ duration: parseInt(duration), watt: parseInt(watt), comment: comment, selected: false });
        }
        else {
            newSteps = createWarmUpSteps(duration, fromWatt, toWatt, comment);

        }
        if (addingMode == "add") {
            setSteps([...steps, ...newSteps]);
        }
        else if (addingMode == "modify") {
            const finalNewSteps = steps.map((x) => x.selected ? newSteps : x).flat()
            setSteps(finalNewSteps)
        }
        else if (addingMode == "addBefore") {
            const finalNewSteps = steps.map((x) => x.selected ? [...newSteps, x] : x).flat()
            setSteps(finalNewSteps)
        }
        setIsModificationMade(true)

    };

    return (
        <Form onFinish={handleSubmit}>
            <Row gutter={16}>
                <Col span={2}>
                    <Switch
                        block
                        checked={warmUp}
                        onChange={(c) => setWarmUp(c)}
                        checkedChildren="Ramp up"
                        unCheckedChildren="Ramp up"
                    />
                </Col>
                <Col span={6}>
                    <Form.Item
                        label="Duration"
                        name="duration"
                        rules={[{ required: true, message: 'Please input the duration!' }]}
                    >
                        <Input
                            type="number"
                            value={duration}
                            onChange={(e) => setDuration(e.target.value)}
                            placeholder="Duration (seconds)"
                            addonAfter="Secs"

                        />
                    </Form.Item>
                </Col>

                {!warmUp ?
                    <Col span={6}>
                        <Form.Item
                            label="Power"
                            name="watt"
                            rules={[{ required: false, message: 'Please input the wattage!' }]}
                        >
                            <Input
                                type="number"
                                value={watt}
                                onChange={(e) => setWatt(e.target.value)}
                                placeholder="Average Watts"
                                addonAfter="Watts"

                            />
                        </Form.Item>
                    </Col> : <>
                        <Col span={4}>
                            <Form.Item
                                label="From"
                                name="fromwatt"
                            //rules={[{ required: true, message: 'Please input the wattage!' }]}
                            >
                                <Input
                                    type="number"
                                    value={fromWatt}
                                    onChange={(e) => setFromWatt(e.target.value)}
                                    placeholder="Initial Watts"
                                    addonAfter="Watts"

                                />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            <Form.Item
                                label="To"
                                name="towatt"
                            //rules={[{ required: true, message: 'Please input the wattage!' }]}
                            >
                                <Input
                                    type="number"
                                    value={toWatt}
                                    onChange={(e) => setToWatt(e.target.value)}
                                    placeholder="Target Watts"
                                    addonAfter="Watts"

                                />
                            </Form.Item>
                        </Col>

                        {/* <Col span={3} hidden={true}>
                            <Form.Item
                                label="Step Watts"
                                name="stepwatt"
                            //rules={[{ required: true, message: 'Please input the wattage!' }]}
                            >
                                <Input
                                    type="number"
                                    value={stepWatt}
                                    onChange={(e) => setStepWatt(e.target.value)}
                                    placeholder="Average Watts"
                                />
                            </Form.Item>
                        </Col> */}
                    </>
                }
                <Col span={warmUp ? 8 : 10}>
                    <Form.Item
                        label="Comment"
                        name="comment"
                    >
                        <Input
                            type="text"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            placeholder="Comment (Write ** RPM in the text to set a target RPM)"
                        />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={4}>
                    <Form.Item>
                        <Button
                            block
                            type="primary"
                            htmlType="submit"
                            onClick={() => setAddingMode("add")}
                            disabled={(!duration || (!watt && (!toWatt || !fromWatt)) || duration.trim() === "")}
                        >
                            Add
                        </Button>
                    </Form.Item>
                </Col>
                <Col span={1}></Col>
                <Col span={4}>
                    <Form.Item>
                        <Button
                            block
                            //type="primary"
                            htmlType="submit"
                            onClick={() => setAddingMode("addBefore")}
                            disabled={(!duration || (!watt && (!toWatt || !fromWatt)) || duration.trim() === "") || steps.filter((x) => x.selected).length == 0}
                        >
                            Add Before Selected
                        </Button>
                    </Form.Item>
                </Col>
                <Col span={1}></Col>
                <Col span={4}>
                    <Form.Item>
                        <Button
                            block
                            //type="primary"

                            htmlType="submit"
                            onClick={() => setAddingMode("modify")}
                            disabled={(!duration || (!watt && (!toWatt || !fromWatt)) || duration.trim() === "") || steps.filter((x) => x.selected).length == 0}
                        >
                            Replace Selected
                        </Button>
                    </Form.Item>
                </Col>
                <Col span={1}></Col>
                <Col span={4}>
                    <Form.Item>
                        <Button disabled={steps.filter((x) => x.selected).length == 0} onClick={duplicateSelectedSteps} block>
                            Duplicate Selected
                        </Button>
                    </Form.Item>
                </Col>
                <Col span={1}></Col>
                <Col span={4}><Form.Item><Button block danger disabled={steps.filter((x) => x.selected).length == 0} onClick={deleteSelectedSteps}>Delete Selected</Button></Form.Item></Col>
            </Row>
        </Form >
    );


}

export default TrainingForm;
