import React, { useState } from 'react';

const DonateButton = ({ isMobile }) => {
    const [showInfo, setShowInfo] = useState(false);

    const handleDonateClick = () => {
        // Open the Buy Me a Coffee link in a new tab
        window.open("https://buymeacoffee.com/sverbo", "_blank");
    };

    return (
        <div style={{ textAlign: 'center' }}>
            <button
                hidden
                onMouseEnter={() => setShowInfo(true)}
                onMouseLeave={() => setShowInfo(false)}
                onClick={handleDonateClick}
                style={{
                    padding: '8px 20px',
                    fontSize: '14px',
                    color: 'black',
                    backgroundColor: 'pink',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                }}
            >
                {!isMobile ? "☕ Buy me a coffee" : "Buy me a ☕"}

            </button>
            <div onMouseEnter={() => setShowInfo(true)}
                onMouseLeave={() => setShowInfo(false)} >
                <a href="https://www.buymeacoffee.com/sverbo" target="_blank"><img height="32px" src={isMobile ? "https://img.buymeacoffee.com/button-api/?text= &emoji=☕&slug=sverbo&button_colour=e287e8&font_colour=000000&font_family=Cookie&outline_colour=000000&coffee_colour=FFDD00" : "https://img.buymeacoffee.com/button-api/?text=Buy me a coffee&emoji=☕&slug=sverbo&button_colour=e287e8&font_colour=000000&font_family=Cookie&outline_colour=000000&coffee_colour=FFDD00"} /></a>
            </div>
            {
                showInfo && (
                    <div
                        style={{
                            position: 'absolute',
                            backgroundColor: '#f9f9f9',
                            border: '1px solid #ddd',
                            borderRadius: '5px',
                            padding: '10px',
                            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                            marginTop: '10px',
                        }}
                    >
                        Your support helps cover hosting fees and keeps this app alive. If you like it and find it useful, I'd be really grateful.
                        <br />
                        Sam 😇
                    </div>
                )
            }
        </div >
    );
};

export default DonateButton;
