import React, { useState, useEffect } from 'react';
import { Bar } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import { Tag, Row, Col, Button, Modal, Rate, Form, Input, Checkbox, Collapse, Popover } from 'antd';
import '../App.css'; // Make sure to import your CSS file
import { SaveOutlined, FireOutlined, LoadingOutlined } from '@ant-design/icons'
import axios from 'axios';

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const tcxHelpers = <div style={{ marginTop: "2%" }}>
    You can download your activity TCX file and manually upload it to most platforms, such as <a target="_blank" href='https://connect.garmin.com/modern/import-data'>Garmin</a> or <a target="_blank" href='https://www.strava.com/upload/select'>Strava</a>. <br />
    You can always download the TCX file later via the "History" tab. <br />
    To save your session as 'virtual biking' on Strava, you need to upload it to Strava via the checkbox above. <br />
    After that, the TCX file will be tagged as "biking" and it won't be possible to change it to "virtual biking".
</div>


const SaveTraining = ({ disabled, weight, steps, initSteps, data, FTP, setFTP, setWeight, infoToSave, FTPResult, otherUserInfo, setOtherUserInfo }) => {
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [tcxLink, setTcxLink] = useState("")
    const [finalWeight, setFinalWeight] = useState(weight)
    const [intensity, setIntensity] = useState(2.5)
    const [comment, setComment] = useState("")
    const [fetchTcxLink, setFetchTcxLink] = useState(false)
    const [finalFTP, setFinalFTP] = useState(FTP)
    const [isLoading, setIsLoading] = useState(false)
    const [uploadToStrava, setUploadToStrava] = useState(false)
    const [stravaAccessCode, setStravaAccessCode] = useState("")


    //console.log(comment, FTPResult)
    const axiosInstance = axios.create({
        baseURL: '/api', // Adjust this baseURL to where your backend is hosted
        headers: {
            'Content-Type': 'application/json'
        }
    });

    // Attach token to every request if available.  Logic needs to be updated in SaveTraining.js as well + Login + App.js
    axiosInstance.interceptors.request.use(config => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    }, error => {
        return Promise.reject(error);
    });

    // Handle response errors.  Logic needs to be updated in SaveTraining.js as well + Login + App.js
    axiosInstance.interceptors.response.use(
        response => response,
        async error => {
            const originalRequest = error.config;

            // Check if the error is due to an expired access token
            if (error.response && error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true; // Prevent infinite retries

                try {
                    // Call /refresh/token to get new tokens
                    const refreshToken = localStorage.getItem('refresh_token');
                    const refreshResponse = await axios.post('api/refresh_tokens/', { refresh_token: refreshToken });

                    if (refreshResponse.status === 200) {
                        const { access_token: newToken, refresh_token: newRefreshToken } = refreshResponse.data;

                        // Store new tokens in localStorage
                        localStorage.setItem('token', newToken);
                        localStorage.setItem('refresh_token', newRefreshToken);

                        // Update Authorization header and retry the original request
                        originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
                        return axiosInstance(originalRequest);
                    }
                } catch (refreshError) {
                    alert("You’ve been inactive for too long. You might need to log in again.")
                    console.error('Failed to refresh token:', refreshError);
                    //localStorage.removeItem('token'); // Remove the stored token
                    //localStorage.removeItem('refresh_token'); // Remove the stored token
                    //setUsername(null)  no access to setUsername. Not logging out automatically so user can download raw data
                    //console.log('Logged out');
                    // Optionally, handle token refresh failure (e.g., logout user)
                }
            }

            return Promise.reject(error);
        }
    );

    function downloadFile(url) {
        const link = document.createElement('a');
        link.href = url;
        // Optionally set the download attribute here to a specific filename
        link.setAttribute('download', 'filename.ext'); // Choose the file name
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }


    const downloafTcxFile = () => {
        const activityData = {
            data: data,
            weight: finalWeight,
            init_steps : initSteps || [],
            steps: steps,
            ftp: finalFTP,
            with_tcx_link: fetchTcxLink,
            intensity: intensity,
            comment: comment,
            strava_refresh_token: uploadToStrava ? otherUserInfo.stravaRefreshToken : null,
            strava_access_code: uploadToStrava ? stravaAccessCode : null,
            ...infoToSave
        }; //adding bike weight in backend
        console.log(activityData)
        function downloadJSON(dictionary, filename) {
            // Convert the dictionary to a JSON string
            const jsonString = JSON.stringify(dictionary, null, 2);

            // Create a Blob object from the JSON string
            const blob = new Blob([jsonString], { type: 'application/json' });

            // Create an anchor element for the download
            const a = document.createElement('a');
            a.href = URL.createObjectURL(blob);
            a.download = filename || 'data.json';

            // Programmatically click the anchor to trigger the download
            document.body.appendChild(a);
            a.click();

            // Clean up the anchor element
            document.body.removeChild(a);
        }

        axiosInstance.post('/download_tcx_file/', activityData)
            .then(response => {
                console.log('TCX link', response);
                setTcxLink(response.data.link);
                setFTP(finalFTP);
                setWeight(finalWeight);
                setIsLoading(false);
                setStravaAccessCode("");
                setOtherUserInfo({ ...otherUserInfo, "stravaRefreshToken": response.data.strava_refresh_token || otherUserInfo.stravaRefreshToken });
                fetchTcxLink ? alert("To proceed to file download, please click on quit on the pop-up that follows, don't worry it won't close your window.") : (response.data.strava_error ? alert(`Well saved but we encountered an issue while trying to upload on Strava  : ${response.data.strava_error}`) : (response.data.upload_id ? alert("Well saved and uploaded to Strava!") : alert("Well saved !")));

            })
            .catch(error => { window.confirm('Error saving your training session. Would you like to download your raw data instead? If this error persists even after checking your internet connection, you can send this file to sam@simplyride.app to recover your session. I apologize for the inconvenience and appreciate your help in reporting this bug to prevent it from happening in the future. Thanks!') && downloadJSON(activityData, "simplyride_session.json"); setTcxLink(""); setIsLoading(false); console.log("error saving session", error); });
    };

    useEffect(() => {
        tcxLink && tcxLink != "" && downloadFile(tcxLink)
    }, [tcxLink]);

    useEffect(() => {
        const newComment = FTPResult === null ? "" : ` (Estimated FTP results : ${FTPResult} Watts)`
        setComment((prevComment) => prevComment.split(" (Estimated FTP results")[0] + newComment)
    }, [FTPResult]);

    useEffect(() => {
        isLoading && downloafTcxFile()
    }, [isLoading]);

    useEffect(() => {
        console.log("mounted", weight)
    }, []);

    return <>
        <Button block type="primary" disabled={disabled} onClick={() => setIsModalOpen(true)}>
            <SaveOutlined />
        </Button>
        <Modal title="Save training" cancelText={"Cancel"} cancelType="default" okButtonProps={{ style: { display: 'none' } }} open={isModalOpen} onCancel={() => setIsModalOpen(false)}>
            <Form onFinish={() => setIsLoading(true)} initialValues={{ weight, comment, intensity, finalFTP, stravaAccessCode }}>
                <Row style={{ marginTop: "5%" }} >
                    <Col span={10}>
                        <Form.Item name="intensity" label={< FireOutlined />} >
                            <Rate allowHalf={true} value={intensity} onChange={(e) => e >= 0 && setIntensity(e)} />
                        </Form.Item>
                    </Col>
                    <Col span={1}></Col>
                    <Col span={13}>
                        <Form.Item name="comment" label="" >
                            <Input.TextArea block onChange={(e) => setComment(e.target.value)} placeholder='Comment'></Input.TextArea>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={11}>
                        <Form.Item style={{ marginTop: "5%" }} name="weight" label="Weight" help="This is needed to estimate your speed. This value will be set as default.">
                            <Input
                                type="number"
                                onChange={(e) => e.target.value > 0 && setFinalWeight(parseInt(e.target.value))}
                                placeholder="Weight (kg)"
                                addonAfter="kg"
                            />
                        </Form.Item>
                    </Col>
                    <Col span={2}></Col>
                    <Col span={11}>
                        <Form.Item style={{ marginTop: "5%" }} name="finalFTP" label="FTP" help={FTPResult ? `This value will be set as default.Feel free to update it to ${FTPResult}` : "This value will be set as default."} >
                            <Input
                                type="number"
                                onChange={(e) => e.target.value > 0 && setFinalFTP(parseInt(e.target.value))}
                                placeholder="FTP"
                                addonAfter="Watt"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Form.Item style={{ marginTop: "2%" }} name="uploadToStrava" value={uploadToStrava} label="Upload activity to Strava ?" >
                            {(otherUserInfo.stravaRefreshToken == null || otherUserInfo.stravaRefreshToken === "invalid_refresh_token") && stravaAccessCode == "" ?
                                < Popover content="You need to sync your Strava account first" >
                                    <Checkbox

                                        checked={false}
                                        disabled={true}
                                        onChange={(e) => setUploadToStrava(e.target.checked)}
                                    />
                                </Popover> :
                                <Checkbox
                                    checked={uploadToStrava}
                                    onChange={(e) => setUploadToStrava(e.target.checked)}
                                />
                            }
                        </Form.Item>
                    </Col>

                    <Col span={12} >
                        <Collapse
                            size="small"
                            style={{ backgroundColor: "#fc4c02" }}
                            items={[{
                                key: '1', label: <span style={{ color: "white", border: "black" }}>{otherUserInfo.stravaRefreshToken ? "Re-sync Strava" : "Sync Strava first"}</span>, children:

                                    <Form.Item style={{ marginBottom: "2%" }} name="stravaAccessCode" label="" >
                                        Click <a href="https://www.strava.com/oauth/authorize?client_id=128363&response_type=code&redirect_uri=https%3A%2F%2Fsimplyride.app%2Fapi%2Fdisplay_code%2F&scope=activity%3Awrite" target="_blank">here</a> and follow the instructions

                                        <Input value={stravaAccessCode} placeholder={otherUserInfo.stravaRefreshToken ? 'Account already sync' : 'Paste your access code here'} onChange={(e) => { setStravaAccessCode(e.target.value); e.target.value != "" ? setUploadToStrava(true) : setUploadToStrava(false) }} />

                                    </Form.Item>


                            }]}
                        /> </Col>
                </Row>
                <Row>
                    <Col span={12}>
                        <Button
                            block
                            style={{ align: "right", marginTop: "5px" }}
                            type="primary"
                            htmlType="submit"
                            name="withTcx"
                            onClick={() => setFetchTcxLink(true)}>
                            {isLoading && fetchTcxLink ? <LoadingOutlined spin={true} /> : "Save and get TCX File"}
                        </Button>
                    </Col>
                    <Col span={12}>
                        <Button
                            block
                            style={{ align: "right", marginLeft: "2%", marginTop: "5px" }}
                            type="default"
                            htmlType="submit"
                            name="noTcx"
                            onClick={() => setFetchTcxLink(false)}>
                            {isLoading && !fetchTcxLink ? <LoadingOutlined spin={true} /> : "Save only"}
                        </Button>
                    </Col>
                </Row>

            </Form>
            <i>{tcxHelpers}</i>

        </Modal>

    </>;
}

export default SaveTraining;

