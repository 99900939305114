import React from 'react';
import { Bar } from 'react-chartjs-2';
import 'chartjs-adapter-date-fns';
import { Tag, Row, Col } from 'antd';
import '../App.css'; // Make sure to import your CSS file

import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);


const TrainingChart = ({ steps, FTP, isMobile }) => {
    // Check if steps array is empty

    const zones = {
        Z1: `<55%-${Math.round(0.55 * FTP)}W`,
        Z2: `<75%-${Math.round(0.75 * FTP)}W`,
        Z3: `<90%-${Math.round(0.9 * FTP)}W`,
        Z4: `<105%-${Math.round(1.05 * FTP)}W`,
        Z5: `<120%-${Math.round(1.2 * FTP)}W`,
        Z6: `<150%-${Math.round(1.5 * FTP)}W`,
        Z7: `Ouch...`,

    }
    const getZoneindex = (intensity) => {
        if (intensity >= FTP * 1.5) {
            return 6; // Red
        } else if (intensity >= FTP * 1.2) {
            return 5; // Yellow
        } else if (intensity >= FTP * 1.05) {
            return 4; // Yellow
        } else if (intensity >= FTP * 0.9) {
            return 3; // Yellow
        } else if (intensity >= FTP * 0.75) {
            return 2; // Yellow
        }
        else if (intensity >= FTP * 0.55) {
            return 1; // blue
        } else {
            return 0;
        }
    };
    function formatTime(seconds) {
        let reverse = false
        if (seconds < 0) {
            seconds = -seconds
            reverse = true
        }
        const minutes = Math.floor(seconds / 60); // Get the full minutes
        const remainingSeconds = seconds % 60;    // Get the remaining seconds
        // Return the formatted time string
        // Add a leading zero to seconds if less than 10
        const timeStr = reverse ? `- ${minutes}'${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}` : `${minutes}'${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
        return timeStr
    }
    const getColor = (intensity) => {
        if (intensity >= FTP * 1.5) {
            return 'rgba(128, 0, 0, 0.8)'; // Red
        } else if (intensity >= FTP * 1.2) {
            return 'rgba(128, 0, 128, 0.8)'; // Yellow
        } else if (intensity >= FTP * 1.05) {
            return 'rgba(255, 0, 0, 0.8)'; // Yellow
        } else if (intensity >= FTP * 0.9) {
            return 'rgba(255, 165, 0, 0.8)'; // Yellow
        } else if (intensity >= FTP * 0.75) {
            return 'rgba(255, 215, 0, 0.8)'; // Yellow
        }
        else if (intensity >= FTP * 0.55) {
            return 'rgba(0, 128, 0, 0.8)'; // blue
        } else {
            return 'rgba(153, 204, 255, 0.8)';
        }
    };
    let cumulativeDuration = 0.5;

    let data = [];

    steps.forEach((step, index) => {
        for (let i = 0; i < step.duration; i++) {
            const x = cumulativeDuration
            data.push({
                x: x, // Position x is the index plus fraction for each minute
                y: step.watt,
                color: getColor(step.watt),
            });
            cumulativeDuration += 1

        }
    });


    const chartData = {
        labels: data.map(substep => substep.x),
        datasets: [{
            label: 'Watts',
            data: data,
            borderWidth: 0.5,
            backgroundColor: data.map(substep => substep.color),
            //borderColor: data.map(substep => substep.color),
            barPercentage: 1.0,
            categoryPercentage: 1.0
        }]
    };

    const options = {
        indexAxis: 'x',
        scales: {
            x: {
                type: 'linear',
                title: {
                    display: true,
                    text: 'Duration (sec)'
                },
                position: 'bottom',
                stacked: true
            },
            y: {
                title: {
                    display: true,
                    text: 'Watts'
                }
            }
        },
        plugins: {
            legend: {
                display: false
            },
            title: {
                display: true,
                text: 'Training Watts over time (sec)'
            }

        },
        // barThickness: "100%"
    };

    //const legendWatt = steps.map(x => x.watt)
    const legendWatt = [0.5 * FTP, 0.7 * FTP, 0.8 * FTP, 1 * FTP, 1.1 * FTP, 1.2 * FTP, 1.7 * FTP]
    const zoneDuration = [0, 0, 0, 0, 0, 0, 0];
    steps.forEach((x) => {
        zoneDuration[getZoneindex(x.watt)] += x.duration
    })
    return <><Bar data={chartData} options={options} />
        <Row style={{ marginTop: "2%" }}>
            <Col span={2}></Col>{legendWatt.map((x) => getColor(x)).map((x, i) => <><Col style={{ marginRight: "2px" }} span={3}><Tag className={isMobile ? "custom-tag-phone" : "custom-tag"} color={x}>{steps.length > 0 ? `Z${i + 1} : ${formatTime(zoneDuration[i])}` : `Z${i + 1}`}</Tag></Col><Col span={0}></Col></>)}
        </Row>
        <Row style={{ marginTop: "2%" }} >
            <Col span={2}></Col>{legendWatt.map((x) => getColor(x)).map((x, i) => <><Col span={3} style={{ marginRight: "2px" }}>{zones[`Z${i + 1}`]}</Col></>)}
        </Row>
        <Row style={{ marginTop: "2%" }}>
            <Col span={2}></Col><i>More info about <a href='https://www.trainerroad.com/blog/cycling-power-zones-training-zones-explained/#:~:text=Cycling%20intensity%20levels%20are%20commonly,is%20crucial%20to%20proper%20training.' target="_blank">cycling power zones</a></i>
        </Row>
    </>;
}

export default TrainingChart;

