import React from 'react';
import { RadialBarChart, RadialBar, PolarAngleAxis, ResponsiveContainer, Tooltip } from 'recharts';
import { Tag } from 'antd';

const PowerGauge = ({ currentPower, targetPower, remainingTime, nextTargetPower, maxPower, isLast, paused }) => {
  const green = "rgba(50, 200, 50, 1)";
  const red = "rgba(250, 0, 0, 1)";

  // Dynamic values
  const diff = (currentPower - targetPower) / targetPower;
  const color = Math.abs(diff) <= 0.1 ? green : red;
  const maxDisplayPower = maxPower ? maxPower * 1.2 : 400;

  const gaugeData = [
    { name: "Target", value: targetPower, fill: "rgba(0,0,65,0.7)" },
    { name: "Current", value: currentPower, fill: paused || remainingTime <= 0 ? "rgba(0,0,65,0.2)" : color },
  ];


  return (
    <ResponsiveContainer width="80%" height={400} style={{marginLeft : "10%"}}>
      <RadialBarChart
        innerRadius="80%"
        outerRadius="100%"
        barSize={15}
        startAngle={90}
        endAngle={-270}
        data={gaugeData}
      >
        {/* PolarAxis hidden for cleaner look */}
        <PolarAngleAxis
          type="number"
          domain={[0, maxDisplayPower]}
          angleAxisId={0}
          tick={false}
        />

        <RadialBar
          minAngle={15}
          background
          clockWise
          dataKey="value"
          isAnimationActive={true}
        />

        {/* Tooltip for interactivity */}
        <Tooltip
          formatter={(value, name) => `${name}: ${value}W`}
          contentStyle={{ backgroundColor: "#fff", borderRadius: "5px", border: "1px solid #ddd" }}
        />

        {/* Centered Text */}
        <text
            x="50%"
            y="50%"
            textAnchor="middle"
            dominantBaseline="middle"
            style={{ fontSize: "20px", fontWeight: "bold", fill: "rgba(0,0,65,0.7)" }}
            >
    <tspan x="50%" dy="0" fontSize={25}>{currentPower} W</tspan>
    {paused ? <tspan x="50%" dy="1.2em" fontSize={20}> Paused</tspan> :  remainingTime > 0 ?  <tspan x="50%" dy="1.2em" fontSize={20}> ➵ 𖦏 {targetPower} W</tspan>
     : <tspan x="50%" dy="1.2em" fontSize={20}>Well done!</tspan>
    }</text>
   
      </RadialBarChart>
    </ResponsiveContainer>
  );
};

export default PowerGauge;