import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

const color = ['rgba(153, 204, 255, 1)', 'rgba(0, 128, 0, 1)', 'rgba(255, 215, 0, 1)', 'rgba(255, 165, 0, 1)', 'rgba(255, 0, 0, 1)', 'rgba(128, 0, 128, 1)', 'rgba(128, 0, 0, 1)', ]

const getZoneDuration = (data, zone) => {
  console.log(data)
  const perc = [0, 0.55, 0.75, 90, 105, 120, 150, 10000].map(p => data.ftp ? p*data.ftp : p*250)

  if (data.durationSteps && data.durationSteps.length>0){
    console.log("ok")
  return data.avgSteps.map((x, i) => (x >= perc[zone-1] && x < perc[zone] ? data.durationSteps[i] : 0)).reduce((a, b) => a + b, 0)
  }
  else {
    return data.avg_watt >= perc[zone-1] && data.avg_watt < perc[zone] ? data.duration : 0 // legacy
  }
}

function estimateDistance(weightCyclist, kjBurned) { //if not mentionned
  const weightBike = 8
  // Données de référence
  const referenceWeightCyclist = 74; // Poids du cycliste de référence en kg
  const referenceWeightBike = 8; // Poids du vélo de référence en kg
  const referenceKjPerKm = 348 / 17.3; // Énergie consommée par km pour 74kg + 8kg

  // Masse totale
  const referenceTotalWeight = referenceWeightCyclist + referenceWeightBike; // Masse totale de référence
  const totalWeight = weightCyclist + weightBike; // Masse totale entrée

  // Ratio d'énergie consommée par km en fonction de la masse
  const kjPerKm = referenceKjPerKm * (totalWeight / referenceTotalWeight);

  // Distance en kilomètres
  const distanceKm = kjBurned / kjPerKm;

  // Conversion en mètres
  const distanceMeters = distanceKm * 1000;
  return distanceMeters; // Distance en mètres
}

const TrainingSummary = ({ initData }) => {

  const [metric, setMetric] = useState("duration"); // "duration" or "kjoules"
  const [timeRange, setTimeRange] = useState("last_12_weeks"); // "last_12_weeks" or "last_12_months"

  //const days=["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saterday", "Sunday"]
  const data = initData.map((x) => ({
    ...x,
    kjoules: x.duration * x.avg_watt / 1000,
    kcal: x.tot_kcal || 0,
    distance : x.total_meters ? x.total_meters/1000 : estimateDistance(x.weight, x.duration * x.avg_watt / 1000)/1000,
    duration_z1: getZoneDuration(x, 1),
    duration_z2: getZoneDuration(x, 2),
    duration_z3: getZoneDuration(x, 3),
    duration_z4: getZoneDuration(x, 4),
    duration_z5: getZoneDuration(x, 5),
    duration_z6: getZoneDuration(x, 6),
    duration_z7: getZoneDuration(x, 7), // Example
  }));

  const getStartOfWeek = (date) => {
    const d = new Date(date);
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1); // Adjust for Monday start
    return new Date(d.setDate(diff));
  };
const getEndOfWeek = (startOfWeek) => {
  const endOfWeek = new Date(startOfWeek);
  endOfWeek.setDate(startOfWeek.getDate() + 6);
  return endOfWeek;
};
  const generateLabels = (groupBy, periods) => {
    const now = new Date();
    const labels = [];
    let current =
      groupBy === "week"
        ? getStartOfWeek(now)
        : new Date(now.getFullYear(), now.getMonth(), 1);
  
    if (groupBy === "day") {
      // Generate "this week" labels (Monday to Sunday)
      const startOfWeek = getStartOfWeek(now);
      for (let i = 0; i < 7; i++) {
        const date = new Date(startOfWeek);
        date.setDate(startOfWeek.getDate() + i);
        labels.push(date.toISOString().slice(0, 10)); // YYYY-MM-DD
      }
    } else {
      for (let i = 0; i < periods; i++) {
        if (groupBy === "week") {
          const startOfWeek = getStartOfWeek(current);
          const endOfWeek = getEndOfWeek(startOfWeek);
          labels.push(
            `${startOfWeek.toISOString().slice(0, 10)} - ${endOfWeek
              .toISOString()
              .slice(0, 10)}`
          );
          current.setDate(current.getDate() - 7); // Go back one week
        } else if (groupBy === "month") {
          const year = current.getFullYear();
          const month = current.getMonth() + 1; // Months are 0-based
          labels.push(`${year}-${month.toString().padStart(2, "0")}`);
          current.setMonth(current.getMonth() - 1); // Go back one month
        }
      }
    }
  
    return groupBy!=="day" ? labels.reverse() : labels;
  };

  const aggregateDataByZones = (filteredData, labels, groupBy) => {
    const baseData = labels.map((label) => ({
      label,
      z1: 0,
      z2: 0,
      z3: 0,
      z4: 0,
      z5: 0,
      z6: 0,
      z7: 0,
      distance : 0,
      kcal : 0,
      kjoules: 0,
    }));

    filteredData.forEach((entry) => {
      const date = new Date(entry.start_time);
      let groupLabel;
  
      if (groupBy === "day") {
        groupLabel = date.toISOString().slice(0, 10);
      } else if (groupBy === "week") {
        const startOfWeek = getStartOfWeek(date);
        const endOfWeek = getEndOfWeek(startOfWeek);
        groupLabel = `${startOfWeek.toISOString().slice(0, 10)} - ${endOfWeek
          .toISOString()
          .slice(0, 10)}`;
      } else {
        groupLabel = `${date.getFullYear()}-${(date.getMonth() + 1)
          .toString()
          .padStart(2, "0")}`;
      }
  
      const found = baseData.find((item) => item.label === groupLabel);
      if (found) {
        for (let i = 1; i <= 7; i++) {
          found[`z${i}`] += entry[`duration_z${i}`] || 0;
        }
        found.kjoules += entry.kjoules || 0;
        found.kcal += entry.kcal || 0;
        found.distance += entry.distance || 0;
      }
    });
  
    return baseData;
  };

  const filterData = () => {
    const now = new Date();
    let filteredData = [];
    let groupBy = "week";
    let periods = 2; // Default to last 2 weeks
    if (timeRange === "last_week") {
      groupBy = "day";
      periods = 1; // 2 weeks including the current week
      filteredData = data;
    }
    else if (timeRange === "last_12_weeks") {
      groupBy = "week";
      periods = 12; // 2 weeks including the current week
      filteredData = data;
    } else if (timeRange === "last_12_months") {
      groupBy = "month";
      periods = 12; // Last 2 months including the current month
      filteredData = data.filter(
        (d) => new Date(d.start_time) >= new Date(now.getFullYear(), now.getMonth() - 11, 1)
      );
    }
    

    const labels = generateLabels(groupBy, periods);
    return { aggregatedData: aggregateDataByZones(filteredData, labels, groupBy), labels };
  };

  const { aggregatedData, labels } = filterData();

  const chartData = {
    labels,
    datasets:
      metric === "duration"
        ? Array.from({ length: 7 }, (_, i) => ({
            label: `Zone ${i + 1}`,
            data: aggregatedData.map((d) => d[`z${i + 1}`] / 3600), // Convert to hours
            backgroundColor: color[i].replace("1)", "0.5)"),
            borderColor: color[i],
            borderWidth: 1,
            stack: "durationStack", // Ensure stacking
          }))
        : metric === "distance" ? 
        [
          {
            label: "Distance (km)",
            data: aggregatedData.map((d) => d.distance),
            backgroundColor: "rgba(0,0,65,0.3)" ,
            borderColor: "rgba(0,0,65, 1)" ,
            borderWidth: 1,
            stack: "energyStack", // Ensure stacking
          },
        ] :
        metric === "kcal" ? 
        [
          {
            label: "Kcal",
            data: aggregatedData.map((d) => d.kcal),
            backgroundColor: "rgba(0,0,65,0.3)" ,
            borderColor: "rgba(0,0,65, 1)" ,
            borderWidth: 1,
            stack: "energyStack", // Ensure stacking
          },
        ]
        :[
            {
              label: "Energy (kJ)",
              data: aggregatedData.map((d) => d.kjoules),
              backgroundColor: "rgba(0,0,65,0.3)" ,
              borderColor: "rgba(0,0,65, 1)" ,
              borderWidth: 1,
              stack: "energyStack", // Ensure stacking
            },
          ],
  };

  return (
    <div style={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <i style={{ display: "flex", justifyContent: "center", gap: "10px", margin: "10px" }}>
        <small>
        *Data reagarding zone and distance prior 30/11/2024 are only but rough estimations
        </small>
        </i>
      <div style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
      
        <button
          style={{
            padding: "10px",
            backgroundColor: metric === "duration" ? "rgba(0,0,65,0.7)" : "#f0f0f0",
            color: metric === "duration" ? "white" : "rgba(0,0,65, 1)",
            border: "1px solid #ddd",
            borderRadius: "5px",
          }}
          onClick={() => setMetric("duration")}
        >
          Duration by Zone
        </button>
        <button
          style={{
            padding: "10px",
            backgroundColor: metric === "distance" ? "rgba(0,0,65,0.7)" : "#f0f0f0",
            color: metric === "distance" ? "white" : "rgba(0,0,65, 1)",
            border: "1px solid #ddd",
            borderRadius: "5px",
          }}
          onClick={() => setMetric("distance")}
        >
          Distance (km)
        </button>
        <button
          style={{
            padding: "10px",
            backgroundColor: metric === "kcal" ? "rgba(0,0,65,0.7)" : "#f0f0f0",
            color: metric === "kcal" ? "white" : "rgba(0,0,65, 1)",
            border: "1px solid #ddd",
            borderRadius: "5px",
          }}
          onClick={() => setMetric("kcal")}
          hidden
        >
          Kcal
        </button>
        <button
          style={{
            padding: "10px",
            backgroundColor: metric === "kjoules" ? "rgba(0,0,65,0.7)" : "#f0f0f0",
            color: metric === "kjoules" ? "white" : "rgba(0,0,65, 1)",
            border: "1px solid #ddd",
            borderRadius: "5px",
          }}
          onClick={() => setMetric("kjoules")}
        >
          KJ (Kw x sec)
        </button>
      </div>
      <div style={{ display: "flex", justifyContent: "center", gap: "10px", marginTop : "10px" }}>
      <button
          style={{
            padding: "10px",
            backgroundColor: timeRange === "last_week" ? "rgba(0,0,65,0.7)" : "#f0f0f0",
            color: timeRange === "last_week" ? "white" : "rgba(0,0,65, 1)",
            border: "1px solid #ddd",
            borderRadius: "5px",
          }}
          onClick={() => setTimeRange("last_week")}
        >
          This Week
        </button>
        <button
          style={{
            padding: "10px",
            backgroundColor: timeRange === "last_12_weeks" ? "rgba(0,0,65,0.7)" : "#f0f0f0",
            border: "1px solid #ddd",
            color: timeRange === "last_12_weeks" ? "white" : "rgba(0,0,65, 1)",
            borderRadius: "5px",
          }}
          onClick={() => setTimeRange("last_12_weeks")}
        >
          Last 3 Months
        </button>
        <button
          style={{
            padding: "10px",
            backgroundColor: timeRange === "last_12_months" ? "rgba(0,0,65,0.7)" : "#f0f0f0",
            border: "1px solid #ddd",
            color: timeRange === "last_12_months" ? "white" : "rgba(0,0,65, 1)",
            borderRadius: "5px",
          }}
          onClick={() => setTimeRange("last_12_months")}
        >
          Last Year
        </button>
        
      </div>


      <div style={{ marginTop: "20px" }}>
        <Bar
          data={chartData}
          options={{
            responsive: true,
            plugins: {
              tooltip: {
                callbacks: {
                  label: (context) =>
                    metric === "duration"
                      ? `${context.dataset.label}: ${context.raw.toFixed(2)} h`
                      : metric === "distance"
                      ? `${context.dataset.label}: ${context.raw.toFixed(2)} km` :
                      metric === "kcal"
                      ? `${context.dataset.label}: ${context.raw.toFixed(2)} kcal` :
                      `${context.dataset.label}: ${context.raw.toFixed(2)} kJ`,
                },
                
              },
            },
            scales: {
              y: {
                beginAtZero: true,
                stacked: true,
                title: {
                  display: true,
                  text:
                    metric === "duration"
                      ? "Duration (hours)"
                      : metric === "distance"
                      ? "Distance (km)" :
                      metric === "kcal"
                      ? "Kcal" :
                      "Energy (kJ)",
                },
              },
              x: {
                stacked: true,
                title: {
                  display: true,
                  text:
                    timeRange === "last_12_months"
                      ? "Months" : timeRange === "last_12_weeks" ?
                      "Weeks" : "Days"
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default TrainingSummary;