import { React, useEffect, useState } from 'react';
import { List, Checkbox, Button, Typography, Row } from 'antd';
const { Text } = Typography;

function formatTime(seconds) {
    let reverse = false
    if (seconds < 0) {
        seconds = -seconds
        reverse = true
    }
    const minutes = Math.floor(seconds / 60); // Get the full minutes
    const remainingSeconds = seconds % 60;    // Get the remaining seconds
    // Return the formatted time string
    // Add a leading zero to seconds if less than 10
    const timeStr = reverse ? `-${minutes}'${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}` : `${minutes}'${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    return timeStr
}

function findLastSelected(steps, index) {
    for (let i = Math.min(index, steps.length - 1); i >= 0; i--) {
        if (steps[i].selected) {
            return i; // Return the index of the last selected step
        }
    }
    return -1; // Return -1 if no selected step is found
}

function TrainingList({ steps, toggleSelectStep, FTP }) {
    const [totTimeFinal, setTotTimeFinal] = useState(0);
    const [totWatt, setTotWatt] = useState(0);

    const getColor = (intensity) => {
        if (intensity >= FTP * 1.5) {
            return 'rgba(128, 0, 0, 0.6)';
        } else if (intensity >= FTP * 1.2) {
            return 'rgba(128, 0, 128, 0.6)';
        } else if (intensity >= FTP * 1.05) {
            return 'rgba(255, 0, 0, 0.6)';
        } else if (intensity >= FTP * 0.9) {
            return 'rgba(255, 165, 0, 0.6)';
        } else if (intensity >= FTP * 0.75) {
            return 'rgba(255, 215, 0, 0.6)';
        }
        else if (intensity > FTP * 0.55) {
            return 'rgba(0, 128, 0, 0.6)';
        } else {
            return 'rgba(153, 204, 255, 0.6)';
        }
    };
    const [isShiftPressed, setIsShiftPressed] = useState(false);

    useEffect(() => {
        // Event handler for keydown
        const handleKeyDown = (event) => {
            if (event.key === 'Shift') {
                setIsShiftPressed(true);
            }
        };

        // Event handler for keyup
        const handleKeyUp = (event) => {
            if (event.key === 'Shift') {
                setIsShiftPressed(false);
            }
        };

        // Add event listeners
        window.addEventListener('keydown', handleKeyDown);
        window.addEventListener('keyup', handleKeyUp);

        // Cleanup listeners on component unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            window.removeEventListener('keyup', handleKeyUp);
        };
    }, []);

    useEffect(() => {
        setTotTimeFinal(steps.map(s => s.duration).reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0,
        ));
        setTotWatt(steps.map(s => s.duration * s.watt).reduce(
            (accumulator, currentValue) => accumulator + currentValue,
            0,
        ));
    }, [steps]);

    return (
        <div>
            <Text>{steps.length} steps, duration: <strong>{formatTime(totTimeFinal)}</strong>, Avg Power: <strong>{steps.length == 0 ? 0 : Math.round(totWatt / totTimeFinal)} Watts</strong></Text>
            <Row style={{ marginTop: "10px" }}>
                <Button type="link" onClick={() => toggleSelectStep(steps.map((x, i) => !x.selected ? i : -1))}>Select all</Button>
                <Button type="link" onClick={() => toggleSelectStep(steps.map((x, i) => x.selected ? i : -1))}>Unselect all</Button>
            </Row>
            <Row style={{ marginBottom: "10px", marginLeft: "10px" }}>Tip : Hold Shift to select multiple</Row>

            <List
                bordered
                dataSource={steps}
                renderItem={(step, index) => (
                    <List.Item style={{ backgroundColor: getColor(step.watt) }}>
                        <Checkbox
                            checked={step.selected || false}
                            onChange={() => toggleSelectStep(
                                isShiftPressed && steps.findIndex(s => s.selected) != -1 && steps.findIndex(s => s.selected) != index
                                    ? (
                                        steps.findIndex(s => s.selected) > index
                                            ?
                                            [index]
                                            :
                                            Array.from({ length: index - findLastSelected(steps, index) }, (_, i) => findLastSelected(steps, index) + i + 1))
                                    : [index]
                            )}
                            style={{ marginRight: '10px' }}
                        />
                        {index + 1}. <Text strong>{formatTime(step.duration)}</Text> at <Text strong>{Math.round(step.watt)} Watts</Text> ({Math.round(step.watt * 100 / FTP)} % FTP). {step.comment}
                    </List.Item>
                )}
            />

        </div>
    );

}

export default TrainingList;
