import React, { useState, useEffect } from "react";
import TrainingForm from "./components/TrainingForm";
import TrainingList from "./components/TrainingList";
import TrainingChart from "./components/TrainingChart";
import HomeTrainer from "./components/HomeTrainer";
import HomeTrainerProgress from "./components/HomeTrainerProgress";
import DonateButton from "./components/DonateButton";
import AppFooter from "./components/Footer";
import LoginForm from "./components/Login";
import FileImporter from "./components/FileImporter";
import TrainingSummary from "./components/DataAnalysis.js";
import {
  Tabs,
  Button,
  Input,
  Form,
  Divider,
  Select,
  Checkbox,
  Slider,
  Typography,
  Row,
  Col,
  Modal,
  Rate,
  Popover,
} from "antd";
import FormItem from "antd/es/form/FormItem";
import {
  ReloadOutlined,
  HeartFilled,
  HeartTwoTone,
  HeartOutlined,
  ControlOutlined,
  RollbackOutlined,
  SoundOutlined,
  MutedOutlined,
  MessageOutlined
} from "@ant-design/icons";
import axios from "axios";
import "./App.css"; // Make sure to import your CSS file
import logo from "./logo_no_bg_free.png";
import logoTurnPhone from "./turnPhone.png";
import { Layout } from "antd";
import useBeforeUnload from "./components/useBeforeUnload";
import History from "./components/History";
// ...later
const { Content, Header } = Layout;

const { TabPane } = Tabs;

const { Option } = Select;
const { Title, Text } = Typography;

const isLocal = window.location.origin.includes("localhost");
const backendBaseUrl = isLocal ? "http://localhost:8000" : "";

function App() {
  const [steps, setSteps] = useState([]);
  const [plansInfo, setPlansInfo] = useState([]);
  const [FTP, setFTP] = useState(null);
  const [weight, setWeight] = useState(null);
  const [stepIndex, setStepIndex] = useState(0);
  const [planName, setPlanName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isFeedbackOpen, setIsFeedbackOpen] = useState(false);
  const [isModificationMade, setIsModificationMade] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({ uuid: 1 });
  const [username, setUsername] = useState(null);
  const [otherUserInfo, setOtherUserInfo] = useState({});
  const [usernameToFetch, setUsernameToFetch] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [favouritePlans, setFavouritePlans] = useState([]);
  const [refFTPForAdjust, setRefFTPForAdjust] = useState(FTP);
  const [planDescription, setPlanDescription] = useState(null);
  const [planCategory, setPlanCategory] = useState(null);
  const [planStressPoints, setPlanStressPoints] = useState(0);
  const [planIsFavourite, setPlanIsFavourite] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [isLandscape, setIsLandscape] = useState(false);
  const [history, setHistory] = useState([]);
  const [reloadTs, setReloadTs] = useState(Date.now());
  const [disabledPhoneButton, setDisabledPhoneButton] = useState(false);
  const [isMute, setIsMute] = useState(true);

  const useBeforeUnload = (handler) => {
    useEffect(() => {
      const handleBeforeUnload = (event) => {
        const returnValue = handler();
        if (returnValue) {
          event.preventDefault();
          event.returnValue = returnValue; // Standard for older browsers
          return returnValue; // Standard for newer browsers
        }
      };

      window.addEventListener("beforeunload", handleBeforeUnload);
      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
      };
    }, [handler]);
  };

  // Usage in your component
  useBeforeUnload(() => {
    if (username) {
      return "Are you sure you want to leave?";
    }
    return undefined; // No message if username is falsy
  });

  useEffect(() => {
    const checkMobile = () => {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      console.log("checking if mobile mode...", userAgent);
      // Regular expression to check if the user agent corresponds to a mobile device
      if (/android|iPad|iPhone|iPod/i.test(userAgent) && !window.MSStream) {
        console.log("Mobile mode");
        setIsMobile(true);
        window.addEventListener("resize", function () {
          setIsLandscape(this.window.innerHeight < this.window.innerWidth || true);//always landscape as it's in the manifest.json, no need anymore
        });
      } else {
        console.log("Not mobile");
        setIsMobile(false);
        setIsLandscape(true);
      }
    };

    checkMobile();
  }, []);

  const axiosInstance = axios.create({
    baseURL: "/api", // Adjust this baseURL to where your backend is hosted
    headers: {
      "Content-Type": "application/json",
    },
  });

  // Attach token to every request if available. Logic needs to be updated in SaveTraining.js as well
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = localStorage.getItem("token");
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Handle response errors Logic needs to be updated in SaveTraining.js as well +  Logic needs to be updated in SaveTraining.js as well + Login + App.js
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config;

      // Check if the error is due to an expired access token
      if (
        error.response &&
        error.response.status === 401 &&
        !originalRequest._retry
      ) {
        originalRequest._retry = true; // Prevent infinite retries

        try {
          // Call /refresh/token to get new tokens
          const refreshToken = localStorage.getItem("refresh_token");
          const refreshResponse = await axios.post("api/refresh_tokens/", {
            refresh_token: refreshToken,
          });

          if (refreshResponse.status === 200) {
            const { access_token: newToken, refresh_token: newRefreshToken } =
              refreshResponse.data;

            // Store new tokens in localStorage
            localStorage.setItem("token", newToken);
            localStorage.setItem("refresh_token", newRefreshToken);

            // Update Authorization header and retry the original request
            originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
            return axiosInstance(originalRequest);
          }
        } catch (refreshError) {
          alert(
            "You’ve been inactive for too long. You will now be logged out."
          );
          console.error("Failed to refresh token:", refreshError);
          localStorage.removeItem("token"); // Remove the stored token
          localStorage.removeItem("refresh_token"); // Remove the stored token
          setUsername(null);
          console.log("Logged out");
          // Optionally, handle token refresh failure (e.g., logout user)
        }
      }

      return Promise.reject(error);
    }
  );

  const calculateTSS = () => {
    if (!FTP || FTP <= 0 || steps.length === 0) {
      setPlanStressPoints(Math.round(0))
    }
    let totalDuration = 0;
    let intensityFactorSum = 0;

    steps.forEach(step => {
      const { duration, watt } = step;
      if (duration && watt) {
        const intensityFactor = watt / FTP; // Relative intensity
        intensityFactorSum += intensityFactor ** 2 * duration; // Weighted by time
        totalDuration += duration;
      }
    });

    if (totalDuration === 0) {
      return 0; // No training time, no TSS
    }

    const normalizedPower = Math.sqrt(intensityFactorSum / totalDuration) * FTP; // Approximated NP
    const tss = (totalDuration * normalizedPower ** 2) / (FTP ** 2 * 3600) * 100;

    setPlanStressPoints(Math.round(tss)); // Return the TSS rounded to the nearest whole number
  }


  const getAllTrainingPlansInfo = ({ uuid }) => {
    axiosInstance
      .post("/get_all_training_plans/", { username_to_fetch: usernameToFetch })
      .then((response) => {
        console.log(response);
        if (response.data.refresh_token) {
          localStorage.setItem("token", response.data.access_token);
          localStorage.setItem("refresh_token", response.data.refresh_token);
        }
        const newPlansInfo = response.data.plans_info.map((x) => ({
          ...x,
          display_name:
            favouritePlans &&
              favouritePlans.includes(x.uuid) &&
              !x.display_name.includes("❤")
              ? "❤ - " + x.display_name
              : x.display_name,
        }));
        setPlansInfo(newPlansInfo);
        selectPlan(uuid, newPlansInfo);
      })
      .catch((error) =>
        console.error("Error retrieving training plans:", error)
      );
  };

  const setFavourite = ({ favourite }) => {
    if (
      favourite &&
      (isModificationMade ||
        (planName && planName != selectedPlan.name) ||
        (planDescription && planDescription != selectedPlan.description) ||
        (planCategory && planCategory != selectedPlan.category))
    ) {
      alert(
        "You are trying to save as favourite a plan under creation or modification. Please save it first."
      );
    } else {
      selectedPlan &&
        selectedPlan.uuid &&
        selectedPlan.uuid != "1" &&
        axiosInstance
          .post("/favourite/", {
            favourite: favourite,
            plan_uuid: selectedPlan.uuid,
          })
          .then((response) => {
            setPlanIsFavourite(favourite);
            setFavouritePlans(response.data.favourite || []);
          })
          .catch((error) => alert("Error changing favourite status."));
    }
  };

  const saveTrainingPlan = () => {
    const trainingData = {
      steps: steps,
      ref_ftp: FTP,
      name: planName,
      description: planDescription,
      category: planCategory,
      stress_points: planStressPoints,
    };
    (plansInfo.filter(
      (x) =>
        x.username == username &&
        x.name == planName &&
        x.category == planCategory
    ).length == 0 ||
      window.confirm(
        `One of your plan is already name as '${planName}', do you want to overwrite it ?`
      )) &&
      axiosInstance
        .post("/save_training_plan/", trainingData)
        .then((response) => {
          setTimeout(function () {
            alert(
              "Training plan well saved, reloading... (wait a few seconds..)"
            );
          }, 1);
          getAllTrainingPlansInfo({ uuid: response.data.uuid });
        })
        .catch((error) => alert("Error saving training", error));
  };

  const sendFeedback = ({ feedback, rating }) => {
    axiosInstance
      .post("/send_feedback/", { feedback, rating })
      .then(() => {
        alert("Feedback well sent");
        setIsFeedbackOpen(false); // Assuming `setIsFeedbackOpen` is a state setter function to close feedback modal/dialog
      })
      .catch((error) => alert("Error when submitting feedback.", error));
  };

  const showModal = (value) => {
    setIsModalOpen(value);
  };

  const handleOk = () => {
    selectPlan(-1, plansInfo);
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const showFeedback = (value) => {
    setIsFeedbackOpen(value);
  };

  const handleFeedbackOk = () => {
    setIsFeedbackOpen(false);
  };

  const handleFeedbackCancel = () => {
    setIsFeedbackOpen(false);
  };

  useEffect(() => {
    if (username) {
      getAllTrainingPlansInfo({ uuid: "1" });
      setRefFTPForAdjust(FTP);
    }
  }, [username]);

  useEffect(() => {
    calculateTSS()
  }, [steps, FTP]);


  useEffect(() => {
    setPlansInfo(
      plansInfo.map((x) => ({
        ...x,
        display_name:
          favouritePlans &&
            favouritePlans.includes(x.uuid) &&
            !x.display_name.includes("❤")
            ? "❤ - " + x.display_name
            : x.display_name &&
              (!favouritePlans || !favouritePlans.includes(x.uuid)) &&
              x.display_name.includes("❤ - ")
              ? x.display_name.slice(4)
              : x.display_name,
      }))
    );
  }, [planIsFavourite]);

  const createWarmUpSteps = (duration, fromWatt, toWatt, comment) => {
    const minStepDuration = 10;
    const newSteps = [];
    if (!duration || !fromWatt || !toWatt || duration < minStepDuration * 2) {
      alert(
        `Duration (>=${minStepDuration * 2
        } secs) and average watts are required!`
      );
      return;
    }

    const toWattInt = parseInt(toWatt);
    const fromWattInt = parseInt(fromWatt);
    const idealWattStep = Math.max(Math.round(FTP / 100), 1) * 5;
    const up = toWattInt - fromWattInt > 0;
    const stepWatt =
      duration / Math.abs(toWattInt - fromWattInt) >=
        minStepDuration / idealWattStep
        ? idealWattStep
        : Math.ceil(
          (minStepDuration * Math.abs(toWattInt - fromWattInt)) / duration
        );
    const stepDuration = Math.ceil(
      duration / (Math.abs(toWattInt - fromWattInt) / stepWatt)
    );
    let totalUsedDuration = 0;
    for (
      let i = fromWattInt;
      (up && i < toWattInt) || (!up && i > toWattInt);
      i = up ? i + stepWatt : i - stepWatt
    ) {
      if (
        (up && i + stepWatt < toWattInt) ||
        (!up && i - stepWatt > toWattInt)
      ) {
        newSteps.push({
          duration: stepDuration,
          watt: i,
          comment: comment,
          selected: false,
        });
        totalUsedDuration += stepDuration;
      } else {
        newSteps.push({
          duration: duration - totalUsedDuration,
          watt: toWattInt,
          comment: comment,
          selected: false,
        });
        i = toWattInt;
      }
    }
    return newSteps;
  };
  const onAdjustPower = () => {
    if (refFTPForAdjust) {
      const newSteps = steps.map((s) => {
        return {
          ...s,
          watt:
            selectedPlan.username == "Test" &&
              selectedPlan.name == "Ramp Up" &&
              s.comment.includes("lap")
              ? s.watt
              : Math.round((s.watt * FTP) / refFTPForAdjust),
        };
      });
      setSteps(newSteps);
      setRefFTPForAdjust(FTP);
    }
  };

  const addStep = (newSteps) => {
    //legacy

    setSteps([...steps, ...newSteps.map((x) => ({ ...x, selected: false }))]);
  };

  const toggleSelectStep = (index) => {
    const newSteps = steps.map((step, i) =>
      index.includes(i) ? { ...step, selected: !step.selected } : step
    );
    setSteps(newSteps);
  };

  const duplicateSelectedSteps = () => {
    const newSteps = [...steps];
    steps.forEach((step) => {
      if (step.selected) {
        newSteps.push({ ...step, selected: false });
      }
    });
    setSteps(newSteps);
    setIsModificationMade(true);
  };

  const deleteSelectedSteps = () => {
    const newSteps = steps.filter((step) => !step.selected);
    setSteps(newSteps);
    setIsModificationMade(true);
  };

  const handlePlanNameChange = (e) => {
    const value = e.target.value;
    setPlanName(value);
  };

  const selectPlan = (uuid, plansInfo) => {
    const newSelectedPlan = plansInfo.find((plan) => plan.uuid === uuid);
    if (newSelectedPlan && uuid != 1) {
      const newSteps = [];

      newSelectedPlan.steps
        .sort((x) => x.order)
        .forEach((step) => {
          const additionalComment =
            step.power == 0
              ? "Free ride ! Feel free to manually adjust your ERG."
              : step.power == 1000
                ? "Best effort ! Feel free to manually adjust your ERG."
                : null;
          step.power =
            step.power == 0
              ? newSelectedPlan.ref_ftp / 3
              : step.power == 1000
                ? newSelectedPlan.ref_ftp * 1.01
                : step.power;
          step.comment =
            !step.comment && additionalComment
              ? additionalComment
              : step.comment;
          step.comment =
            step.comment && step.rpm
              ? `@ ${step.rpm} rpm ` + step.comment
              : step.rpm
                ? `@ ${step.rpm} rpm`
                : step.comment;
          if (!step.power_to) {
            newSteps.push({
              watt:
                newSelectedPlan.username == "Test" &&
                  newSelectedPlan.name == "Ramp Up" &&
                  step.power >= 100
                  ? step.power
                  : Math.round((step.power / newSelectedPlan.ref_ftp) * FTP),
              duration: step.duration_sec,
              comment: step.comment,
              selected: false,
            });
          } else {
            const warmUpSteps = createWarmUpSteps(
              step.duration_sec,
              Math.round((step.power / newSelectedPlan.ref_ftp) * FTP),
              Math.round((step.power_to / newSelectedPlan.ref_ftp) * FTP),
              step.comment
            );
            newSteps.push(...warmUpSteps);
          }
        });

      setSteps(newSteps);
      setSelectedPlan(newSelectedPlan);
      setPlanDescription(newSelectedPlan.description);
      setPlanCategory(newSelectedPlan.category);
      setRefFTPForAdjust(FTP);
      setIsModificationMade(false);
      setPlanName(newSelectedPlan.name);
      setPlanIsFavourite(favouritePlans.includes(newSelectedPlan.uuid));
      setPlanStressPoints(newSelectedPlan.stress_points || 0);
    } else if (uuid != selectedPlan.uuid || uuid === -1) {
      setSelectedPlan({ uuid: 1 });
      setSteps([]);
      setIsModificationMade(false);
      setPlanName(null);
      setPlanStressPoints(0);
      setPlanDescription(null);
      setPlanIsFavourite(false);
      setPlanCategory(null);
    }
  };

  const config = {
    title: "Use Hook!",
    content: "test",
  };
  const title = "SimplyRide.app";
  const slogan = "Plan, train, share.";

  if (username) {
    return (
      <Layout style={{ backgroundColor: "rgba(22, 40, 226, 0.05)" }}>
        <Content>
          <div>
            <div
              style={{ margin: "2%", display: isLandscape ? true : "none" }}
              className="content"
            >

              <Row gutter={16}>
                <Col span={isMobile ? 10 : 11}></Col>
                <Col span={5}>
                  {" "}
                  <DonateButton isMobile={false}></DonateButton>
                </Col>
                <Col span={2}>
                  <Popover content="Toggle sound on/off to indicate you'll be starting a new interval step">
                    <Button
                      danger={isMute}
                      block
                      onClick={() => setIsMute(!isMute)}
                    >
                      {isMute ? <MutedOutlined /> : <SoundOutlined />}
                    </Button>
                  </Popover>
                </Col>
                <Col span={2}>
                  <Button block type="primary" onClick={showFeedback}>
                    <MessageOutlined />
                  </Button>
                  <Modal
                    title="Send Feedback"
                    cancelText={"Close"}
                    cancelType="default"
                    cancelButtonProps={{ style: { display: "none" } }}
                    okButtonProps={{ type: "default" }}
                    okText="Back"
                    open={isFeedbackOpen}
                    onOk={handleFeedbackOk}
                    onCancel={handleFeedbackCancel}
                  >
                    <Form
                      onFinish={(e) => sendFeedback(e)}
                      style={{ align: "right" }}
                    >
                      <Form.Item name="feedback">
                        <Input.TextArea placeholder="Let me know if you desire to get contacted when a feature related to your feedback will be deployed/fixed."></Input.TextArea>
                      </Form.Item>
                      <Form.Item
                        name="rating"
                        label="How do you like the app ?"
                      >
                        <Rate allowHalf={true} />
                      </Form.Item>
                      <Button
                        style={{ align: "right" }}
                        type="primary"
                        htmlType="submit"
                        block
                      >
                        Send
                      </Button>
                      <p>Or email to <a href="mailto:sam@simplyride.app" target="_blank">sam@simplyride.app</a>.</p>

                    </Form>
                  </Modal>
                </Col>


                <Col span={isMobile ? 5 : 4}>
                  <LoginForm
                    username={username}
                    setUsername={setUsername}
                    title={title}
                    slogan={slogan}
                    setIsAdmin={setIsAdmin}
                    setFTP={setFTP}
                    setWeight={setWeight}
                    setFavouritePlans={setFavouritePlans}
                    setHistory={setHistory}
                    isMobile={isMobile}
                    reloadTs={reloadTs}
                    setOtherUserInfo={setOtherUserInfo}
                  />
                </Col>

              </Row>
              <Row>
                <Col span={isMobile ? 2 : 1}>
                  <img
                    src={logo}
                    style={{
                      height: "50px",
                      marginLeft: "-10px",
                      marginTop: "15px",
                      marginBottom: "-15px",
                    }}
                    alt={title}
                  />
                </Col>
                <Col span={isMobile ? 22 : 23}>
                  <Title
                    level={1}
                    style={{ color: "#031932", marginLeft: "-5px" }}
                  >
                    {title}
                    <small style={{ color: "grey", fontSize: "15px" }}>
                      {" "}
                      {slogan}
                    </small>
                  </Title>
                </Col>
              </Row>
              <Tabs
                defaultActiveKey={isMobile ? "3" : "1"}
                tabBarStyle={{
                  borderBottom: "0px", // Customize the bottom border of the tab bar
                }}
                style={{ marginBottom: 32 }}
                onTabClick={(k, e) => {
                  k == "1" &&
                    steps.length > 0 &&
                    alert(
                      "If you modify the current plan or load a new one, any potential ongoing training will be lost."
                    );
                  k == "4" && setReloadTs(Date.now());
                }}
              >
                {!isMobile && (
                  <TabPane tab="Plan Setup" key="1">
                    <Form layout="vertical">
                      <Row gutter={16}>
                        <Col span={4}>
                          <Form.Item label=" ">
                            <Button
                              block
                              type="primary"
                              onClick={saveTrainingPlan}
                              disabled={!FTP || steps.length === 0 || !planName}
                            >
                              Save Training Plan
                            </Button>{" "}
                          </Form.Item>
                        </Col>
                        <Col span={5}>
                          <Form.Item label="Plan Name">
                            <Input
                              value={planName}
                              onChange={handlePlanNameChange}
                            />
                          </Form.Item>
                        </Col>

                        <Col span={3}>
                          <Form.Item label="Category">
                            <Input
                              value={planCategory}
                              placeholder="(Optional)"
                              onChange={(e) => setPlanCategory(e.target.value)}
                            />
                          </Form.Item>
                        </Col>
                        <Col span={3}>
                          <Form.Item label=" ">
                            <FileImporter setPlanName={setPlanName} setSteps={setSteps} setFTP={setFTP} setDescription={setPlanDescription} FTP={FTP} steps={steps} planName={planName} description={planDescription} createWarmUpSteps={createWarmUpSteps} isMobile={isMobile} />
                          </Form.Item>
                        </Col>
                        <Col span={3}>
                          <Popover
                            content={
                              <p>
                                - You can load existing plans from friends.
                                <br />- Simply enter their username and refresh.
                                <br />- If left blank (default), your plans,
                                favorites, and Zwift's plans are loaded.
                              </p>
                            }
                            title="How does it work?"
                          >
                            <Form.Item label="Fetch plan from">
                              <Input
                                placeholder="username"
                                onChange={(e) =>
                                  setUsernameToFetch(e.target.value)
                                }
                              ></Input>
                            </Form.Item>
                          </Popover>
                        </Col>

                        <Col span={5}>
                          <Popover
                            content={
                              <p>
                                - You can load existing plans from templates,
                                friends, or your own. <br />- Refresh to list
                                recently saved plans. <br />- You can also reset
                                to a blank plan.
                              </p>
                            }
                            title="What are those icons?"
                          >
                            <Form.Item
                              label={
                                <>
                                  {" "}
                                  <ReloadOutlined
                                    onClick={() =>
                                      getAllTrainingPlansInfo({ uuid: 1 })
                                    }
                                  />{" "}
                                  <RollbackOutlined
                                    style={{ marginLeft: "20%" }}
                                    onClick={() => {
                                      isModificationMade ||
                                        (planName &&
                                          planName != selectedPlan.name) ||
                                        (planDescription &&
                                          planDescription !=
                                          selectedPlan.description) ||
                                        (planCategory &&
                                          planCategory != selectedPlan.category)
                                        ? showModal("1")
                                        : selectPlan("1", plansInfo);
                                    }}
                                  />
                                </>
                              }
                            >
                              <Select
                                style={{ width: "100%" }}
                                popupMatchSelectWidth={false}
                                showSearch={true}
                                optionFilterProp="label"
                                onChange={(value) => {
                                  isModificationMade ||
                                    (planName && planName != selectedPlan.name) ||
                                    (planDescription &&
                                      planDescription !=
                                      selectedPlan.description) ||
                                    (planCategory &&
                                      planCategory != selectedPlan.category)
                                    ? showModal(value)
                                    : selectPlan(value, plansInfo);
                                }}
                                value={selectedPlan.uuid}
                                placeholder="Choose existing plan"
                              >
                                {[
                                  {
                                    uuid: 1,
                                    display_name: "Load training session",
                                  },
                                  ...plansInfo,
                                ]
                                  .sort((a, b) => {
                                    if (a.uuid == "1") {
                                      return -1;
                                    } else if (b.uuid == "1") {
                                      return 1;
                                    }
                                    if (
                                      a.display_name.includes("❤") &&
                                      !b.display_name.includes("❤")
                                    ) {
                                      return -1;
                                    } else if (
                                      !a.display_name.includes("❤") &&
                                      b.display_name.includes("❤")
                                    ) {
                                      return 1;
                                    } else {
                                      if (
                                        a.username == username &&
                                        b.username != username
                                      ) {
                                        return -1;
                                      } else if (
                                        a.username != username &&
                                        b.username == username
                                      ) {
                                        return 1;
                                      } else {
                                        return a.display_name < b.display_name
                                          ? -1
                                          : 1;
                                      }
                                    }
                                  })
                                  .map((planInfo, i) => (
                                    <Option
                                      key={planInfo.uuid}
                                      label={planInfo.display_name}
                                      value={planInfo.uuid}
                                    >
                                      {planInfo.display_name}
                                    </Option>
                                  ))}
                              </Select>
                            </Form.Item>
                          </Popover>

                          <Modal
                            title="Discard"
                            open={isModalOpen}
                            onOk={handleOk}
                            onCancel={handleCancel}
                          >
                            <p>Discard current changes/plan ?</p>
                            <p>
                              You can always save the current one for later use.
                            </p>
                          </Modal>
                        </Col>
                        <Col
                          span={1}
                          style={{ display: "flex", justifyContent: "left" }}
                        >
                          <Popover
                            content={
                              <p>
                                Save this plan as favourite to better find it
                                later !
                              </p>
                            }
                            title="Save for later ?"
                          >
                            <Form.Item label=" ">
                              {planIsFavourite ? (
                                <HeartFilled
                                  style={{ color: "red", fontSize: "20px" }}
                                  onClick={() =>
                                    setFavourite({ favourite: false })
                                  }
                                />
                              ) : (
                                <HeartOutlined
                                  disabled={true}
                                  style={{ color: "black", fontSize: "20px" }}
                                  onClick={() =>
                                    setFavourite({ favourite: true })
                                  }
                                />
                              )}
                            </Form.Item>
                          </Popover>
                        </Col>
                      </Row>
                      <Row gutter={16}>
                        <Col span={11}>
                          <Form.Item label="Description">
                            <Input.TextArea
                              block
                              value={planDescription}
                              onChange={(e) =>
                                setPlanDescription(e.target.value)
                              }
                              placeholder="Training Description (optional)"
                            ></Input.TextArea>
                          </Form.Item>
                        </Col>
                        <Col span={4}>
                          <Form.Item
                            label={`Training Stress Score (${planStressPoints || "NA"
                              })`}
                            help={
                              <a
                                target="_blank"
                                href="https://zwiftinsider.com/all-about-tss/"
                              >
                                More info about TSS
                              </a>
                            }
                          >
                            <Slider
                              min={0}
                              max={200}
                              onChange={(e) => setPlanStressPoints(e)}
                              value={
                                planStressPoints > 200 ? 200 : planStressPoints
                              }
                            />
                          </Form.Item>
                        </Col>
                        <Col span={7}>
                          <Popover
                            content={
                              <p>
                                Search for 'Test - FTP' training sessions to
                                estimate your FTP. <br /> You can explore the
                                different tests by reading their descriptions.{" "}
                                <br /> Your FTP score will be provided upon
                                completion of the test. <br />
                                <br /> <b>Tip : </b>To enter the desired power
                                as a percentage of your FTP instead of the
                                actual value, set this field to 100. <br />
                                Enter the watts as a percentage of your FTP,
                                then reset the field to your actual FTP when
                                finished. <br />
                                Don’t forget to click “Adjust”!
                              </p>
                            }
                            title="How to test your FTP ?"
                          >
                            <Form.Item
                              label="FTP"
                              help={
                                selectedPlan.ref_ftp
                                  ? `Selected plan FTP is ${selectedPlan.ref_ftp}W. It has been adjust to your last ref : (${refFTPForAdjust}W).`
                                  : refFTPForAdjust != FTP
                                    ? `Last ref FTP : ${refFTPForAdjust}`
                                    : ""
                              }
                            >
                              <Input
                                type="number"
                                addonAfter="Watts"
                                value={FTP}
                                onChange={(e) =>
                                  e.target.value >= 0 &&
                                  setFTP(parseInt(e.target.value))
                                }
                              />
                            </Form.Item>
                          </Popover>
                        </Col>
                        <Col span={2}>
                          <Form.Item label="Adjust">
                            <Popover
                              content={
                                <p>
                                  It allows you to adjust all power (Watts) of
                                  the session according to your FTP.
                                  <br />
                                  To build your plan based on FTP %, set 100 as
                                  FTP reference. Adjust to your actual FTP at
                                  the end.
                                </p>
                              }
                              title="What is that ?"
                            >
                              <Button block onClick={onAdjustPower}>
                                <ControlOutlined />
                              </Button>
                            </Popover>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Form>

                    <TrainingForm
                      setSteps={setSteps}
                      steps={steps}
                      duplicateSelectedSteps={duplicateSelectedSteps}
                      deleteSelectedSteps={deleteSelectedSteps}
                      setIsModificationMade={setIsModificationMade}
                      createWarmUpSteps={createWarmUpSteps}
                    />
                    <Row>
                      <Col span={8}>
                        <TrainingList
                          steps={steps}
                          toggleSelectStep={toggleSelectStep}
                          FTP={FTP}
                        />
                      </Col>
                      <Col span={16}>
                        {
                          //<div style={{ maxHeight: "400px" }}>
                        }
                        <TrainingChart
                          steps={steps}
                          FTP={FTP}
                          isMobile={isMobile}
                        />
                        {
                          //</div>
                        }
                      </Col>
                    </Row>
                  </TabPane>
                )}
                {!isMobile && (
                  <TabPane tab="Let's Ride !" key="2">
                    <Row>
                      <Col span={15}>
                        <HomeTrainer
                          onConnect={(device) =>
                            console.log("Connected to", device.name)
                          }
                          onDisconnect={() => console.log("Disconnected")}
                          initSteps={steps}
                          FTP={FTP}
                          setStepIndex={setStepIndex}
                          isAdmin={isAdmin}
                          setFTP={setFTP}
                          setWeight={setWeight}
                          weight={weight}
                          infoToSave={{
                            isModificationMade,
                            selectedPlan,
                            planName,
                            otherUserInfo,
                          }}
                          otherUserInfo={otherUserInfo}
                          setOtherUserInfo={setOtherUserInfo}
                          isMobile={isMobile}
                          setDisabledPhoneButton={setDisabledPhoneButton}
                          isMute={isMute}
                        />
                      </Col>
                      <Col span={1}></Col>

                      <Col span={8}>
                        <HomeTrainerProgress
                          steps={steps}
                          FTP={FTP}
                          currentIndex={stepIndex}
                        />
                      </Col>
                    </Row>
                  </TabPane>
                )}
                {isMobile && (
                  <TabPane tab="Let's ride !" key="3">
                    <Row gutter={16}>
                      <Col span={2}>
                        <FileImporter setPlanName={setPlanName} setSteps={setSteps} setFTP={setFTP} setDescription={setPlanDescription} FTP={FTP} steps={steps} planName={planName} description={planDescription} createWarmUpSteps={createWarmUpSteps} isMobile={isMobile} />
                      </Col>
                      <Col span={5}>
                        <Popover
                          content={
                            <p>
                              - You can load existing plans from friends.
                              <br />- Simply enter their username and refresh.
                              <br />- If left blank (default), your plans,
                              favorites, and Zwift's plans are loaded.
                            </p>
                          }
                          title="How does it work?"
                        >
                          <Form.Item>
                            <Input
                              disabled={disabledPhoneButton}
                              placeholder="Plan's Username"
                              onChange={(e) =>
                                setUsernameToFetch(e.target.value)
                              }
                            ></Input>
                          </Form.Item>
                        </Popover>
                      </Col>
                      <Col
                        span={1}
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <Form.Item>
                          <ReloadOutlined
                            style={{ fontSize: "20px" }}
                            onClick={() => !!disabledPhoneButton && getAllTrainingPlansInfo({ uuid: 1 })}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={8}>
                        {" "}
                        <Select
                          block
                          disabled={disabledPhoneButton}
                          style={{ width: "100%" }}
                          popupMatchSelectWidth={false}
                          showSearch={true}
                          optionFilterProp="label"
                          onChange={(value) => {
                            isModificationMade ||
                              (planName && planName != selectedPlan.name) ||
                              (planDescription &&
                                planDescription != selectedPlan.description) ||
                              (planCategory &&
                                planCategory != selectedPlan.category)
                              ? showModal(value)
                              : selectPlan(value, plansInfo);
                          }}
                          value={selectedPlan.uuid}
                          placeholder="Choose existing plan"
                        >
                          {[
                            { uuid: 1, display_name: "Load training session" },
                            ...plansInfo,
                          ]
                            .sort((a, b) => {
                              if (a.uuid == "1") {
                                return -1;
                              } else if (b.uuid == "1") {
                                return 1;
                              }
                              if (
                                a.display_name.includes("❤") &&
                                !b.display_name.includes("❤")
                              ) {
                                return -1;
                              } else if (
                                !a.display_name.includes("❤") &&
                                b.display_name.includes("❤")
                              ) {
                                return 1;
                              } else {
                                if (
                                  a.username == username &&
                                  b.username != username
                                ) {
                                  return -1;
                                } else if (
                                  a.username != username &&
                                  b.username == username
                                ) {
                                  return 1;
                                } else {
                                  return a.display_name < b.display_name
                                    ? -1
                                    : 1;
                                }
                              }
                            })
                            .map((planInfo, i) => (
                              <Option
                                key={planInfo.uuid}
                                label={planInfo.display_name}
                                value={planInfo.uuid}
                              >
                                {planInfo.display_name}
                              </Option>
                            ))}
                        </Select>
                      </Col>
                      <Col
                        span={1}
                        style={{ display: "flex", justifyContent: "left" }}
                      >
                        <Popover
                          content={
                            <p>
                              Save this plan as favourite to better find it
                              later !
                            </p>
                          }
                          title="Save for later ?"
                        >
                          <Form.Item>
                            {planIsFavourite ? (
                              <HeartFilled
                                style={{ color: "red", fontSize: "20px" }}
                                onClick={() =>
                                  !disabledPhoneButton && setFavourite({ favourite: false })
                                }
                              />
                            ) : (
                              <HeartOutlined
                                disabled={true}
                                style={{ color: "black", fontSize: "20px" }}
                                onClick={() =>
                                  setFavourite({ favourite: true })
                                }
                              />
                            )}
                          </Form.Item>
                        </Popover>
                      </Col>
                      <Col span={5}>
                        <Form.Item
                          help={
                            selectedPlan.ref_ftp
                              ? `Plan FTP : ${selectedPlan.ref_ftp}W. Last ref : (${refFTPForAdjust}W).`
                              : refFTPForAdjust != FTP
                                ? `Last ref FTP : ${refFTPForAdjust}`
                                : ""
                          }
                        >
                          <Input
                            type="number"
                            addonAfter="Watts"
                            value={FTP}
                            disabled={disabledPhoneButton}
                            onChange={(e) =>
                              e.target.value >= 0 &&
                              setFTP(parseInt(e.target.value))
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col span={2}>
                        <Form.Item>
                          <Popover
                            content={
                              <p>
                                It allows you to adjust all power (Watts) of the
                                session according to your FTP.
                                <br />
                                To build your plan based on FTP %, set 100 as
                                FTP reference. Adjust to your actual FTP at the
                                end.
                              </p>
                            }
                            title="What is that ?"
                          >
                            <Button block onClick={onAdjustPower} disabled={disabledPhoneButton}>
                              <ControlOutlined />
                            </Button>
                          </Popover>
                        </Form.Item>
                      </Col>
                    </Row>
                    <p />
                    <Row>
                      <Col span={24}>
                        <HomeTrainer
                          onConnect={(device) =>
                            console.log("Connected to", device.name)
                          }
                          onDisconnect={() => console.log("Disconnected")}
                          initSteps={steps}
                          FTP={FTP}
                          setStepIndex={setStepIndex}
                          isAdmin={isAdmin}
                          setFTP={setFTP}
                          setWeight={setWeight}
                          weight={weight}
                          infoToSave={{
                            isModificationMade,
                            selectedPlan,
                            planName,
                            otherUserInfo,
                          }}
                          otherUserInfo={otherUserInfo}
                          setOtherUserInfo={setOtherUserInfo}
                          isMobile={isMobile}
                          setDisabledPhoneButton={setDisabledPhoneButton}
                          isMute={isMute}
                        />
                      </Col>
                    </Row>
                    <p />
                  </TabPane>
                )}
                <TabPane tab="History" key="4">
                  <History history={history} isMobile={isMobile}></History>
                </TabPane>
                <TabPane
                  tab="Data Analysis (beta)"
                  key="5"
                >
                  <TrainingSummary initData={history}>
                  </TrainingSummary>

                </TabPane>
              </Tabs>
            </div>
            <div
              style={{ margin: "10%", display: !isLandscape ? true : "none" }}
            >
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={logo}
                  style={{
                    height: "100px",
                    marginBottom: "-50px",
                    marginLeft: "-20px",
                  }}
                  alt="SimplyRide.app"
                />
              </div>

              <Title
                style={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "50px",
                  color: "#031932",
                }}
                level={1}
              >
                {title}
              </Title>
              <Title
                style={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "-20px",
                  color: "#031932",
                }}
                level={1}
              >
                <small style={{ color: "grey", fontSize: "15px" }}>
                  {" "}
                  {slogan}
                </small>
              </Title>
              <div
                style={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={logoTurnPhone}
                  style={{ height: "150px" }}
                  alt="SimplyRide.app"
                />
              </div>
              <small
                style={{
                  alignItems: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                Please turn your phone
              </small>
            </div>
          </div>
        </Content>
        <AppFooter isMobile={isMobile} backendBaseUrl={backendBaseUrl} />
      </Layout >
    );
  } else {
    return (
      <>
        <Layout
          style={{
            minHeight: "100vh",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "rgba(22, 40, 226, 0.05)",
          }}
        >
          <Content style={{ flex: "1 0 auto", padding: "20px" }}>
            <LoginForm
              username={username}
              setUsername={setUsername}
              setHistory={setHistory}
              title={title}
              slogan={slogan}
              setIsAdmin={setIsAdmin}
              setFTP={setFTP}
              setWeight={setWeight}
              setFavouritePlans={setFavouritePlans}
              isMobile={isMobile}
              reloadTs={reloadTs}
              setOtherUserInfo={setOtherUserInfo}
            />
          </Content>
          <AppFooter isMobile={isMobile} backendBaseUrl={backendBaseUrl} />
        </Layout>
      </>
    );
  }
}

export default App;
