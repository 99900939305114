import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import FloatingDemoButton from './components/FloatingDemoButton';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));

// Add a redirection here
if (window.location.href === 'https://simplyride-hynfqd5e2a-ew.a.run.app/') {
  window.location.href = 'https://simplyride.app';
}
else {

  root.render(
    <React.StrictMode>
      <App />
      <FloatingDemoButton />
    </React.StrictMode>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
