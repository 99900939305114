import React, { useState } from "react";
import { FundProjectionScreenOutlined } from '@ant-design/icons'

const FloatingDemoButton = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isHovered, setIsHovered] = useState(false);

    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    return (
        <>
            {/* Floating Button */}

            <div
                style={{
                    ...floatingButtonStyle,
                    ...(isHovered ? floatingButtonHoverStyle : {}),
                }}
                onMouseEnter={() => setIsHovered(true)} // Handle hover start
                onMouseLeave={() => setIsHovered(false)} // Handle hover end
                onClick={openModal} // Open modal logic
            >
                <FundProjectionScreenOutlined />

            </div>

            {/* Modal */}
            {isModalOpen && (
                <div style={modalOverlayStyle} onClick={closeModal}>
                    <div
                        style={modalContentStyle}
                        onClick={(e) => e.stopPropagation()} // Prevent closing modal on content click
                    >

                        <iframe
                            width="560"
                            src="https://www.youtube.com/embed/-Fn1EvewpUg"
                            title="SimplyRide.app Demo Video"
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            style={{ width: "100%", height: "100%" }}
                        ></iframe>
                        <button style={closeButtonStyle} onClick={closeModal}>
                            x
                        </button>
                    </div>
                </div>
            )}
        </>
    );
};

const floatingButtonStyle = {
    position: "fixed",
    bottom: "20px", // Distance from the bottom of the screen
    right: "100px", // Distance from the right of the screen
    backgroundColor: "#ffffcc", // Light yellow background
    color: "#333", // Dark text color for contrast
    width: "60px", // Set fixed width for circle
    height: "60px", // Set fixed height for circle
    borderRadius: "50%", // Makes the element a perfect circle
    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)", // Subtle shadow
    cursor: "pointer", // Pointer cursor on hover
    zIndex: 1000, // Ensure it stays on top of other elements
    fontSize: "24px", // Slightly smaller text size
    fontWeight: "bold", // Bold text
    display: "flex", // Center the text inside
    alignItems: "center", // Vertically center the text
    justifyContent: "center", // Horizontally center the text
    transition: "transform 0.4s ease", // Smooth scaling animation
};

const floatingButtonHoverStyle = {
    transform: "scale(1.1)", // Increases size by 50%
};

// Modal overlay styles
const modalOverlayStyle = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100vw",
    height: "100vh",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2000,
};

// Modal content styles
const modalContentStyle = {
    backgroundColor: "#fff",
    overflow: "hidden",
    position: "relative",
    width: "80%",
    aspectRatio: "16 / 9", // Ensures the correct height for a 16:9 video
};

// Close button styles
const closeButtonStyle = {
    position: "absolute",
    top: "10px",
    right: "10px",
    backgroundColor: "#ffffcc",
    color: "black",
    border: "none",
    borderRadius: "50%",
    width: "30px",
    height: "30px",
    cursor: "pointer",
    fontWeight: "bold",
};

export default FloatingDemoButton;