import React from 'react';
import { Layout, Space } from 'antd';
import { ReloadOutlined, HeartFilled, HeartTwoTone, HeartOutlined, ControlOutlined, RollbackOutlined } from '@ant-design/icons'

const { Footer } = Layout;

//if you change the footer, change it also in backend src/html_form.py
const AppFooter = ({ isMobile, backendBaseUrl }) => {
    return (
        <Footer style={{ textAlign: 'center', backgroundColor: 'rgba(22, 40, 226, 0.07)', padding: '20px 50px' }}>
            <div style={{ marginBottom: '10px', color: 'rgba(0, 0, 0, 0.45)' }}>
                Made with <HeartFilled style={{ color: "red", margin: "3px" }} /> by Sam - v1.3
            </div >

            <Space direction="horizontal" size="large">
                <a href="mailto:sam@simplyride.app" target="_blank" style={{ color: '#1890ff' }}>
                    Contact
                </a>
                <a href={`${backendBaseUrl}/api/support/`} style={{ color: '#1890ff' }} target="_blank">
                    Support - FAQ
                </a>

                <a href="https://medium.com/@letsgeekabit/building-the-app-i-couldnt-find-a-simple-free-lightweight-solution-for-indoor-cycling-workouts-32544c12451a" target="_blank" style={{ color: '#1890ff' }}>
                    Blog Post
                </a>
            </Space>
            <div style={{ marginTop: '10px', color: 'rgba(0, 0, 0, 0.45)' }}>
                © 2024 SimplyRide.app All rights reserved. - <b>{isMobile ? "More features on computer" : "Try the mobile version"}!</b>
            </div>
        </Footer >
    );
};

export default AppFooter;