import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Col, Row, Typography, Tabs } from 'antd';
import axios from 'axios';
import { LogoutOutlined } from '@ant-design/icons'
import logo from '../logo_no_bg_free.png';

const { Title, Text } = Typography;
const { TabPane } = Tabs;

const isLocal = window.location.origin.includes("localhost");
const backendBaseUrl = isLocal ? "http://localhost:8000" : "";

function LoginForm({ username, setUsername, title, slogan, setIsAdmin, setFTP, setWeight, setFavouritePlans, isMobile, setHistory, reloadTs, setOtherUserInfo }) {
    const [key, setKey] = useState("1")

    const axiosInstance = axios.create({
        baseURL: "/api", // Adjust this baseURL to where your backend is hosted
        headers: {
            'Content-Type': 'application/json'
        }
    });

    const axiosInstanceWithToken = axios.create({
        baseURL: "/api", // Adjust this baseURL to where your backend is hosted
        headers: {
            'Content-Type': 'application/json'
        }
    });


    useEffect(() => {
        if (username) {
            getUserInfo()
        }
    }, [reloadTs, username]);


    useEffect(() => {
        const timeout = setTimeout(() => {
            if (!username) {
                getUserInfo();
            }
        }, 100); // Wait for 300ms before running, to be sure token is well removed when logged out

        return () => clearTimeout(timeout); // Clean up on re-render
    }, []);


    // Attach token to every request if available  Logic needs to be updated in SaveTraining.js as well + Login + App.js
    axiosInstanceWithToken.interceptors.request.use(config => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    }, error => {
        return Promise.reject(error);
    });

    // Handle response errors Logic needs to be updated in SaveTraining.js as well + Login + App.js
    axiosInstanceWithToken.interceptors.response.use(
        response => response,
        async error => {
            const originalRequest = error.config;

            // Check if the error is due to an expired access token
            if (error.response && error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true; // Prevent infinite retries

                try {
                    // Call /refresh/token to get new tokens
                    const refreshToken = localStorage.getItem('refresh_token');
                    const refreshResponse = await axios.post('api/refresh_tokens/', { refresh_token: refreshToken });

                    if (refreshResponse.status === 200) {
                        const { access_token: newToken, refresh_token: newRefreshToken } = refreshResponse.data;

                        // Store new tokens in localStorage
                        localStorage.setItem('token', newToken);
                        localStorage.setItem('refresh_token', newRefreshToken);

                        // Update Authorization header and retry the original request
                        originalRequest.headers['Authorization'] = `Bearer ${newToken}`;
                        return axiosInstanceWithToken(originalRequest);
                    }
                } catch (refreshError) {
                    alert("You’ve been inactive for too long. You will now be logged out.")
                    console.error('Failed to refresh token:', refreshError);
                    localStorage.removeItem('token'); // Remove the stored token
                    localStorage.removeItem('refresh_token'); // Remove the stored token
                    setUsername(null)
                    console.log('Logged out');
                    // Optionally, handle token refresh failure (e.g., logout user)
                }
            }

            return Promise.reject(error);
        }
    );

    const getUserInfo = async () => {
        if (localStorage.getItem('token')) {
            axiosInstanceWithToken.post('/get_user_info/', {})
                .then(response => {
                    if (response.data.refresh_token) {
                        localStorage.setItem("token", response.data.access_token);
                        localStorage.setItem("refresh_token", response.data.refresh_token);
                    }
                    console.log("Token still in storage, getting user info", response.data.username, response.data.is_admin);
                    setUsername(response.data.username);
                    setHistory(response.data.history || []);
                    setIsAdmin(response.data.is_admin);
                    setFTP(response.data.ftp || 250);
                    setWeight(response.data.weight || 75);
                    setFavouritePlans(response.data.favourite || []);
                    setOtherUserInfo((prevInfo) => ({ ...prevInfo, "stravaRefreshToken": response.data.strava_refresh_token || null }));
                })
                .catch(error => console.error('Error retrieving userInfo', error));
        };

    };

    const handleSubmit = async (values) => {
        try {
            const response = await axios.post(`/api/token/`, new URLSearchParams({
                username: values.username,
                password: values.password
            }));
            console.log("token received")

            localStorage.setItem('token', response.data.access_token); // Store the token
            localStorage.setItem('refresh_token', response.data.refresh_token); // Store the token
            setUsername(response.data.username)
            setIsAdmin(response.data.is_admin)
            setFTP(response.data.ftp || 250)
            setWeight(response.data.weight || 75)
            setHistory(response.data.history || [])
            setFavouritePlans(response.data.favourite || [])
            setOtherUserInfo((prevInfo) => ({ ...prevInfo, "stravaRefreshToken": response.data.strava_refresh_token || null }))

        } catch (error) {
            alert(`Failed to sign-in : ${error.response.data.detail}`)
            //alert('Failed to login, please verify your username and password. If you just signed up, be sure you have clicked on the verification link that has been sent to you via email. If you have never received such email or you forgot your password, please contact support at sam@simplyride.app');
            console.error('Failed to login, please verify your username and password.', error);
        }
    };
    const handleSubmitSignUp = async (values) => {
        if (values.password != values.password2 || values.password.length < 8) {
            alert("Both passwords needs to be identical with at least 8 characters");
        }
        const data = {
            username: values.username,
            password: values.password,
            full_name: values.fullName,
            email: values.email
            //access_key: values.accessKey
        }
        axiosInstance.post('/sign_up_with_access_key/', { data })
            .then(response => {
                console.log(response)
                alert(`Well signed up ${response.data.username} ! You should have received an email (check also your spam) from me in order to verify your address. Just click on the link inside and you're ready to ride !`);
                setKey("1")
            })
            .catch(error => { console.log("e", error); alert(`Failed to sign-up : ${error.response.data.detail}`); })
    }



    const handleLogout = () => {
        localStorage.removeItem('token'); // Remove the stored token
        localStorage.removeItem('refresh_token'); // Remove the stored token
        setUsername(null)
        console.log('Logged out');
    };
    return (
        <>{!username && (<div style={{ margin: "2%" }}>
            <div style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                <img src={logo} style={{ height: "50px", marginTop: "10px", marginBottom: "-50px", marginLeft: "-10px" }} alt="SimplyRide.app" /></div>

            <Title style={{ alignItems: "center", display: "flex", justifyContent: "center", marginTop: "50px", color: "#031932" }} level={1}>{title}</Title>
            <Title style={{ alignItems: "center", display: "flex", justifyContent: "center", marginTop: "-20px", color: "#031932" }} level={1}><small style={{ color: 'grey', fontSize: "15px" }}> {slogan}</small></Title>
            <Tabs defaultActiveKey={key} activeKey={key} onChange={(k) => setKey(k)} style={{ alignItems: "center", display: "flex", justifyContent: "center", marginTop: "20px" }} >
                <TabPane tab="Sign in" key="1">
                    <Form onFinish={handleSubmit} layout="vertical">
                        <Row>
                            <Col style={{ width: isMobile ? "300px" : "500px", padding: "20px", border: "1px solid #ccc", boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }}>
                                <Form.Item
                                    block
                                    label="Username"
                                    name="username"
                                    rules={[{ required: true, message: 'Please input your username!' }]}
                                >
                                    <Input block />
                                </Form.Item>
                                <Form.Item
                                    label="Password"
                                    name="password"
                                    rules={[{ required: true, message: 'Please input your password!' }]}
                                >
                                    <Input.Password />
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">
                                        Log In
                                    </Button>
                                </Form.Item>
                                <a href={`${backendBaseUrl}/api/reset_password/reset/`} target="_blank">Reset password</a>
                            </Col>
                        </Row>
                    </Form>
                </TabPane>
                <TabPane tab="Sign up" key="2">
                    <Form onFinish={handleSubmitSignUp} layout="vertical" style={{ alignItems: "center", display: "flex", justifyContent: "center", marginTop: "50px" }}>
                        <Row>
                            <Col style={{ width: isMobile ? "300px" : "500px", padding: "20px", border: "1px solid #ccc", boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)" }}>
                                <Form.Item
                                    block
                                    label="Username"
                                    name="username"
                                    rules={[{ required: true, message: 'Please input your username!' }]}
                                >
                                    <Input block />
                                </Form.Item>
                                <Form.Item
                                    block
                                    label="Full Name"
                                    name="fullName"
                                    rules={[{ required: true, message: 'Please input your username!' }]}
                                >
                                    <Input block />
                                </Form.Item>
                                <Form.Item
                                    block
                                    label="Email"
                                    name="email"
                                    rules={[{ required: true, message: 'Please input your username!' }]}
                                >
                                    <Input block />
                                </Form.Item>
                                <Form.Item
                                    label="Password"
                                    name="password"
                                    help="Min 8 characters."
                                    rules={[{ required: true, message: 'Please input your password!' }]}
                                >
                                    <Input.Password />
                                </Form.Item>
                                <Form.Item
                                    label="Repeat password"
                                    name="password2"
                                    rules={[{ required: true, message: 'Please input your password!' }]}
                                >
                                    <Input.Password />
                                </Form.Item>
                                <Form.Item
                                    hidden
                                    label="Access key"
                                    name="accessKey"
                                    help="As this is a beta version, only invited users can register for now. Feel free to contact me at sam@simplyride.app to get one ;)"
                                    rules={[{ required: false, message: 'Please input your password!' }]}
                                >
                                    <Input.Password />
                                </Form.Item>
                                <Form.Item style={{ marginTop: "35px" }}>
                                    <Button type="primary" htmlType="submit">
                                        Sign up
                                    </Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </TabPane>
            </Tabs>
            {isMobile && <i style={{ alignItems: "center", display: "flex", justifyContent: "center", marginTop: "20px" }} ><Col style={{ width: "300px", textAlign: "justify" }}>Note : You are accessing <b>the phone version, which should be used in landscape mode. </b><br /> If you want access to full features such as creating your own plan or having more data during training sessions, feel free to use it on a computer.<br />  While the TCX file can be downloaded from here, you can always retrieve it from the history tab on your computer after it has been saved.</Col></i>}

        </div>)
        }

            {
                username && (
                    <Button block onClick={() => window.confirm("Leaving already? Any ongoing session or training will be lost. See you next time!") && handleLogout()}>
                        <LogoutOutlined /> {username}
                    </Button>
                )
            }
        </>
    );
}

export default LoginForm;
